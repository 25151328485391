import { Player } from 'video-react';
import { ContentType } from '../types/Enums';
import ReactPlayer from 'react-player/youtube';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useEffect, useRef, useState } from 'react';
import { QuestionSideNav } from '../types/Course';
import QuestionList from './QuestionList';

const VideoContentType = ({
  contentType,
  url,
  questionsData,
}: {
  contentType: number;
  url: string;
  questionsData: QuestionSideNav[] | undefined;
}) => {

 

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  console.log("url------->",url)
  function onDocumentLoadSuccess(numPages: any) {
    setNumPages(numPages);
  }
  switch (contentType) {
    case ContentType.VIDEO.valueOf():
      return (
        <div className=" sm:p-10 pb-0">
          <Player
            playsInline
            poster="https://media.istockphoto.com/id/1340716614/photo/abstract-icon-representing-the-ecological-call-to-recycle-and-reuse-in-the-form-of-a-pond.jpg?s=1024x1024&w=is&k=20&c=qCgLki6nJ_PUS_4SEQ8Jwrot5BM4XPRUqMP8KkWjFH8="
            src={
              url ? url : 'https://media.w3.org/2010/05/sintel/trailer_hd.mp4'
            }
            autoPlay
           
          />
        </div>
      );
    case ContentType.IMAGE.valueOf():
      return (
        <>
          <img src={url} className="p-10" alt="No Image file" />
        </>
      );
    case ContentType.YOUTUBE_VIDEO.valueOf():
      return (
        <div className="">
          <ReactPlayer
            url={url}
            width={1100}
            height={600}
            className="p-10 pl-10"
          />
        </div>
      );
    case ContentType.DOCUMENT.valueOf():
      return (
        <div className="mx-28 pt-10">
          <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
          <p>Page {pageNumber}</p>
        </div>
      );
    case ContentType.QUESTION.valueOf():
      return (
        <>
          <QuestionList questionsData={questionsData} />
        </>
      );
    default:
      return <></>;
  }
};

export default VideoContentType;
