// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useEffect, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { AiFillLock } from 'react-icons/ai';
import DashboardHeader from '../components/dashboard-header/DashboardHeader';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import Footer from '../components/footer/Footer';
import useTranslation from '../utils/translation';
import useCourseService from '../components/course/CourseService';
import { ContentSideNav, CourseEnrolled } from '../types/Course';
import { useParams } from 'react-router-dom';
import useLoading from '../components/loading/LoadingHook';
import { useContentService } from '../components/content/ContentService';
import { ProgresStatus } from '../types/Enums';
import VideoContentType from './VideoContentType';
import CustomButton from '../components/custom-button/CustomButton';
import useProgressService from '../components/course-progress/ProgressService';

function Video() {
  const [activeLink, setActiveLink] = useState(Number);
  const [lessonId, setLessonId] = useState(Number);
  const [courseData, setCourseData] = useState<CourseEnrolled>();
  const [contents, setContentData] = useState<ContentSideNav>();

  const [contentId, setContentId] = useState('');
  const [progressId, setProgressId] = useState('');
  const [buttonSelected, setButtonSelected] = useState('');
  const [url, setUrl] = useState('');
  const [chapterId, setChapterId] = useState('');
  const progressService = useProgressService();
  const courseService = useCourseService();
  const contentService = useContentService();
  const loading = useLoading();
  let { id } = useParams();
  const [courseId, setCourseId] = useState(id);
  function setOpenVedio(id: number) {
    if (lessonId === id) {
      setLessonId(0);
    } else {
      setLessonId(id);
    }
  }
  const handleNextContentData = () => {
    progressService
      .updateContentProgress(contentId, progressId)
      .then((onSuccess) => {
        if (onSuccess) {
          setContentId(onSuccess.data.content);
          setButtonSelected('bg-custom_green-900');
        }
      });
  };
  const handlePreviousContentData = () => {
    // setContentId(0000000);
    setButtonSelected('bg-custom_green-900');
  };
  useEffect(() => {
    if (contentId) {
      contentService
        .getContent(contentId)
        .then(({ data: contents }) => {
          setContentData(contents);
          setUrl(contents.url);
          setProgressId(contents.content_progress.id);
          console.log(chapterId, contents?.chapter);
          if (chapterId !== contents?.chapter) {
            setLessonId(lessonId + 1);
            setChapterId(contents?.chapter);
          }
        })
        .catch((error) => loading.stopLoading());
    }
  }, [contentId]);

  useEffect(() => {
    courseService
      .getEnrolledCourse(courseId ? courseId : '')
      .then(({ data: courses }) => {
        setCourseData(courses);
        loading.stopLoading();
      })
      .catch((error) => loading.stopLoading());
  }, [courseId, contentId]);

  return (
    <>
      <DashboardHeader />
      <div className="relative">
        <div className="w-full h-full">
          <div className="pl-0  pr-0 ">
            <div className="flex h-full w-full flex-wrap">
              <div className=" flex flex-col  items-center  w-full lg:w-3/4  lg:mb-0">
                <div className=" w-full">
                  <VideoContentType
                    contentType={activeLink}
                    questionsData={contents?.question_content}
                    url={url}
                  />
                </div>

                <div className="px-3 self-start w-full md:px-10">
                  <div className="flex justify-between items-start lg:items-center flex-col lg:flex-row">
                    <h2 className="lg:max-w-sm font-medium text-2xl text-custom_black-300 mt-3 mb-0">
                      {contents?.title}
                    </h2>
                    <p className="flex items-center justify-center text-gray-500 md:self-end">
                      {' '}
                      <FiEye className="text-red-500 text-xl mr-1" />{' '}
                      <span className="text-red-500 text-xl mr-1">8,350</span>{' '}
                      Students are watching
                    </p>
                  </div>
                </div>
                <div className="flex justify-between w-64 mb-5  ">
                  <div className='mr-3 '>
                  <CustomButton
                    text={'Previous'}
                    fun={() => {
                      handlePreviousContentData();
                    }}
                    
                  />
                  </div>
                  <div >
                  <CustomButton
                    text={'Next'}
                    fun={() => {
                      handleNextContentData();
                    }}
                  />
                  </div>
                </div>
               
              </div>
              <div className="w-full lg:w-1/4 bg-custom_green-400 ">
                <div className="bg-custom_green-300 flex justify-between items-center  py-4  lg:py-5 lg:items-start pl-6  lg:flex-col">
                  <h3 className="text-lg font-medium mb-0  text-custom_black-200 md:text-2xl">
                    Course Content
                  </h3>
                  <span className="text-lg font-medium lg:mt-3 block text-custom_green-900 md:text-xl">
                    {courseData?.chapter_course.length} Lessons (8h 15m)
                  </span>
                </div>
                <div className="">
                  <div className="accordion" id="videoPlaylist">
                    {/* <!-- Accordion Items Start  --> */}

                    {courseData?.chapter_course.map((chapter) => (
                      <div key={chapter.id} className="accordion-item">
                        <button
                          disabled={
                            chapter?.chapter_progress !==
                            ProgresStatus.STARTED.valueOf()
                              ? true
                              : false
                          }
                          className={`border-0  text-left w-full border-b-2 flex justify-between  ${
                            chapter?.chapter_progress !==
                            ProgresStatus.STARTED.valueOf()
                              ? 'opacity-50 cursor-not-allowed'
                              : ''
                          } ${
                            chapter?.chapter_number === lessonId
                              ? 'text-custom_green-900'
                              : ''
                          } `}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          onClick={() => [
                            setOpenVedio(chapter?.chapter_number),
                            setChapterId(chapter?.id),
                          ]}
                        >
                          <div className="py-4 lg:py-1">
                            <p
                              className={`pl-5 text-[15px] ${
                                chapter?.chapter_number === lessonId
                                  ? 'text-custom_green-900'
                                  : 'text-custom_black-200'
                              }`}
                            >
                              {chapter?.chapter_name}: {chapter?.chapter_title}
                            </p>
                            <span className="pl-5 text-custom_black-700">
                              01 hour 48 minutes
                            </span>
                          </div>
                          <div className="pr-8 lg:pr-3">
                            {chapter?.chapter_number === lessonId ? (
                              <RiArrowDropUpLine className="text-2xl"></RiArrowDropUpLine>
                            ) : (
                              <RiArrowDropDownLine className="text-2xl"></RiArrowDropDownLine>
                            )}
                            {chapter?.chapter_progress ===
                            ProgresStatus.STARTED.valueOf() ? (
                              ''
                            ) : (
                              <AiFillLock></AiFillLock>
                            )}
                          </div>
                        </button>
                        {chapter?.content_chapter?.map((content) => (
                          <div
                            key={content.id}
                            className={`${
                              chapter?.chapter_number === lessonId
                                ? ''
                                : 'hidden'
                            }`}
                          >
                            <nav
                              className={`vids ${
                                content?.content_progress
                                  ?.content_progress_status ===
                                  ProgresStatus.STARTED.valueOf() ||
                                content?.content_progress
                                  ?.content_progress_status ===
                                  ProgresStatus.FINISHED.valueOf()
                                  ? ''
                                  : 'opacity-50 cursor-not-allowed'
                              }`}
                            >
                              <div
                                onClick={() => {
                                  if (buttonSelected !== '') {
                                    if (contentId === content.id) {
                                      setButtonSelected('');
                                    } else {
                                      setContentId(content?.id);
                                    }
                                  } else {
                                    setContentId(content?.id);
                                    setButtonSelected('bg-custom_green-900');
                                  }
                                  setActiveLink(content?.content_type);
                                  setProgressId(content?.content_progress.id);
                                }}
                                className="border-b-2 py-3 lg:py-1 pr-7 relative cursor-pointer"
                              >
                                <span className="p-2 absolute top-[10px] left-[20px] ">
                                  {content?.content_progress
                                    ?.content_progress_status ===
                                    ProgresStatus.FINISHED.valueOf() ||
                                  content?.content_progress
                                    ?.content_progress_status ===
                                    ProgresStatus.STARTED.valueOf() ? (
                                    ''
                                  ) : (
                                    <AiFillLock></AiFillLock>
                                  )}
                                </span>
                                <span
                                  className={`p-2 absolute top-[13px] left-[48px] w-[13px] h-[13px] rounded-[50%]  border-[3px] ${
                                    contentId === content.id
                                      ? buttonSelected
                                      : ''
                                  } border-custom_green-900`}
                                ></span>
                                <p className=" pl-20   p-2">
                                  {content?.content_number}. {content?.title}
                                </p>
                                <span className=" pl-16 text-custom_black-700">
                                  08 minutes
                                </span>
                              </div>
                            </nav>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Video;
