import DownloadSection from "../components/download/DownloadSection";
import Footer from "../components/footer/Footer";
import User from "../components/auth/User";
import Header from "../components/header/Header";
import useUserPages from "../components/auth/UserPages";

function Login() {

  const UserPages = useUserPages()

  return (
    <>
      <Header title="Login" activeTab="login" />
      <User page={UserPages.login} />
      <DownloadSection />
      <Footer />
    </>
  );
}

export default Login;
