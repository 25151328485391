import CustomInput from "../custom-input/CustomInput";
import { useState } from "react";
import CustomTextArea from "../custom-input/CustomTextArea";
import CustomButton from "../custom-button/CustomButton";
import CustomText from "../custom-text/CustomText";
import { Category } from "../../types/Category";
import useCategoryService from "../category/CategoryService";
import { BackEndError } from "../../types/UserItem";
import CustomInputError from "../custom-input-error/CustomInputError";
import useCategoryApi from "./CategoryApi";
import { SubmitHandler, useForm } from "react-hook-form";
import useLoading from "../loading/LoadingHook";
import { useNavigate } from "react-router-dom";



function CategoryAdd(prop:any) {
  const {setLoad} = prop
  // const [category_name, setCategoryName] = useState<string>('');
  // const [description, setCategoryDescription] = useState<string>('');
  const { register, handleSubmit, formState: { errors } } = useForm<Category>();
  const categoryService = useCategoryService()
  const loading = useLoading()
  //  const [cursor, setCursorData] = useState('')
  //  const categoryApi = useCategoryApi()  
  //  const navigate = useNavigate()
  const onSubmit : SubmitHandler<Category> = (data) => {
    loading.startLoading()
    console.log(data)
    // const formData = new FormData()
    // formData.append("category_name", category_name)
    // formData.append("description", description)
    categoryService.addCategory(data)
      .then(({data: value}) => {
        loading.stopLoading()
        // navigate(`${value.id}`)
        setLoad(true)
      })
  };


  return (
    <>
      <h1 className="text-center text-2xl my-2">
        Add  <CustomText text="Category" />
      </h1>
      <div className="container mx-auto grid grid-cols-1 mb-10">

        <form  onSubmit={handleSubmit(onSubmit)}>
            <CustomInput
              register={register}
              label="category_name"
              error={errors.category_name}
              options={{
                required: "Category Name is required"
              }}
              placeholder="Category Name"
            />
            <CustomTextArea
              register={register}
              label="description"
              error={errors.description}
              options={{
              required: "Category Description is required"
              }}
              placeholder="Description"
            />
            <CustomButton type="submit" text="Add Category" />
        </form>
      </div>
    </>
  );
}

export default CategoryAdd;
