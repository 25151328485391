import useApi from "../../../utils/api";
import { FetchData } from "../../../types/FetchData";
import { Group } from "../../../types/GroupPermission";

const useGroupService = () => {
  const { commonApi } = useApi();

  const getGroup = (id: string) => {
    const response = commonApi.get<Group>(`groups/${id}/`);
    return response;
  };
 
  const getGroups = (data: { pageSize:number,cursor: string, searchText: string }) => {
    const response = commonApi.get<FetchData<Group>>("groups/", {
      params: {
        ps:data.pageSize,
        cursor:data.cursor,
        search: data.searchText
      },
    });
    return response;
  };
 
  const addGroup = (groups: Group) => {
    const response = commonApi.post<Group>("groups/",
    groups, {headers: {
        'content-type': 'multipart/form-data'
    }});
    console.log("response", response);
    return response;
  };
  return {getGroups, getGroup, addGroup }
};

export default useGroupService
