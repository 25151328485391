import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { RootState } from "../../slicers/store";
import { Permission } from "../../types/UserItem";
import useToast from "../toast/ToastHook";

const useAuthorization = () => {

  const user = useSelector((state:RootState) => state.user)
  const navigator = useNavigate()
  const toast = useToast()

  const isLoggedIn = (): boolean => {
    return user.username != null;
  }

  
  const requireLogin = (): void  => {
    if (!user.username) {
      navigator(-1)
    } 
  }

  const isSysAdmin = (): boolean => {
    return user.is_superuser
  }

  const requireSysAdmin = (): void => {
    if (!isSysAdmin()) {
      navigator(-1)
      toast.alert("You are not system admin")
    }
  }

  const hasPermission = (uri: string, permission: Permission): boolean => {
    return user.converted_user_permission && user.converted_user_permission[uri] && user.converted_user_permission[uri][permission]
  }

  const canCreate = (uri: string): boolean => {
    if (isSysAdmin()) {
      return true;
    }
    return hasPermission(uri, Permission.CAN_CREATE);
  }

  const requireCreate = (uri: string): boolean => {
    if (!canCreate(uri)) {
      navigator(-1)
      toast.alert("You cannot create")
      return false;
    }
    return true;
  }

  const canRead = (uri: string): boolean => {
    if (isSysAdmin()) {
      return true;
    }
    return hasPermission(uri, Permission.CAN_VIEW);
  }

  const requireRead = (uri: string): boolean => {
    if (!canRead(uri)) {
      navigator(-1)
      toast.alert("You cannot read")
      return false;
    }
    return true;
  }

  const canUpdate = (uri: string): boolean => {

    if (isSysAdmin()) {
      return true;
    }
    return hasPermission(uri, Permission.CAN_CHANGE);
  }

  const requireUpdate = (uri: string): boolean => {
    if (!canUpdate(uri)) {
      navigator(-1)
      toast.alert("You cannot update")
      // this.tcNavigator.accessDenied();
      // this.dialogRef.closeAll();
      // this.tcNotification.error("You have not access to edit")
      return false
    }
    return true
  }

  const canDelete = (uri: string): boolean => {
    if (isSysAdmin()) {
      return true;
    }
    return hasPermission(uri, Permission.CAN_DELETE);
  }

  const requireDelete = (uri: string): boolean => {
    if (!canDelete(uri)) {
      navigator(-1)
      toast.alert("You cannot delete")
      return false;
    }
    return true;
  }


return {isLoggedIn, requireLogin, canCreate, requireCreate, canUpdate, requireUpdate, canRead, requireRead, canDelete, requireDelete, requireSysAdmin}

}

export default useAuthorization

export function isVisible(visibility: boolean): string{
  return visibility ? '' : 'hidden'
}