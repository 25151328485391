import CustomInput from "../custom-input/CustomInput";
import CustomGroupInput from "../custom-input/CustomGroupInput";
import CustomDropdown from "../custom-input/CustomDropdown";
import { useEffect, useState } from "react";
import CustomTextArea from "../custom-input/CustomTextArea";
import courseImg from "../../assets/images/course_add.png";
import CustomButton from "../custom-button/CustomButton";
import CustomText from "../custom-text/CustomText";
import { Category } from "../../types/Category";
import useCategoryService from "../category/CategoryService";
import useCourseService from "./CourseService";
import { BackEndError } from "../../types/UserItem";
import CustomInputError from "../custom-input-error/CustomInputError";
import CustomImageFileInput from "../custom-input/CustomImageFileInput";
import { Course } from "../../types/Course";
import useLoading from "../loading/LoadingHook";
import Loading from "../loading/Loading";
import useTranslation from "../../utils/translation";
import useCategoryApi from "../category/CategoryApi";
import { RootState } from "../../slicers/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { SelectItem } from "../../types/MenuItems";
import useFileService from "../file/FileService";


function CourseAdd() {
  const [categories, setCategories] = useState<SelectItem<string>[]>([]);
  const { register, setError, control, handleSubmit, formState: { errors } } = useForm<Course>();
  const courseService = useCourseService()
  const loading = useLoading()
  const {translate} = useTranslation()
  const user = useSelector((state: RootState) => state.user)
  const navigate = useNavigate()
  const categoryService = useCategoryService()
  const fileService = useFileService()

  const courseLevel: SelectItem<number>[] = [
    { value: 100, label: "Beginner" },
    { value: 101, label: "Intermediate" },
    { value: 102, label: "Advanced" },
  ];
  const courseType: SelectItem<number>[] = [
    { value: 100, label: "Free" },
    { value: 101, label: "Premium" },
    { value: 102, label: "Sponsored" },
  ];

  const getCategoryData = (filterText: string) => {
    categoryService.getCategories({
      pageSize: 5,
      cursor: '',
      searchText: filterText
    }).then(({data: response} )=> {
      setCategories(response.results.map((resp) => ({
        label: resp.category_name,
        value: resp.id
      })))
    })
  
  };

  useEffect(() => {
    getCategoryData("")
  }, [])

  const onSubmit : SubmitHandler<Course> = (data) => {
    loading.startLoading()
    const formData = new FormData()
    // formData.append("name", data.name)
    formData.append(
      "url",
      data.course_image[0] as Blob,
      (data.course_image[0] as File)?.name
    );

    formData.append("file_type",'101');

    fileService.addFile(formData).then(({data:value})=>{
      loading.stopLoading()
      data.course_image = value.url
      data.category = [data.categorys]
      courseService.addCourse(data)
      .then(({data: value}) => {
        loading.stopLoading()
        navigate(`${value.id}`)
      }).catch(
      (error) => {
        loading.stopLoading()
        const errors = error.response.data.error.details;
                for (const err in errors){
                    if (err == 'non_field_errors'){
                        setError('root', {message: errors[err]})
                    }else {
                        setError(err as keyof Course, {message: errors[err]})
                    }
                }
                loading.stopLoading()
      }
      )
    }).catch(()=>{
      loading.stopLoading()
    })
  };

  return (
    <div className="relative">
    <Loading {...loading} />
      <h1 className="text-center text-4xl my-2">
        Add <CustomText text="Course" />
      </h1>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2">
        <div>
          <img src={courseImg} alt="Course Add" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-7">
            <CustomInput
              register={register}
              label="name"
              error={errors.name}
              options={{
                required: "Course Name is required"
              }}
              placeholder="Course Name"
            />
            <CustomDropdown
              placeholder="Category"
              data={categories}
              register={register}
              control={control}
              label='categorys'
              options={{
                required: 'Category is required'
              }}
              isSearchable={true}
              error={errors.categorys}
            />
            <CustomDropdown
              placeholder="Level"
              id='id'
              val="name"
              data={courseLevel}
              register={register}
              label='course_level'
              options={{
                required: 'Course Level is required'
              }}
              control={control}
              isSearchable={false}
              error={errors.course_level}
            />
            <CustomDropdown
              placeholder="Type"
              data={courseType}
              control={control}
              register={register}
              label='course_type'
              options={{
                required: 'Course Type is required'
              }}
              isSearchable={false}
              error={errors.course_type}
              onInputChange={getCategoryData}
            />
            <CustomTextArea
              register={register}
              label="description"
              error={errors.description}
              options={{
              required: "Course Description is required"
              }}
              placeholder="Description"
            />
            <CustomGroupInput
              register={register}
              label="course_code"
              error={errors.course_code}
              options={{
                required: "Course Code is required"
              }}
              prefix={translate("course_code")}
              placeholder="Course Code"
            />
            <CustomGroupInput
              register={register}
              label="course_price"
              error={errors.course_price}
              options={{
                required: "Course Price is required"
              }}
              prefix={translate("price")}
              placeholder=""
            />
            <CustomGroupInput
              register={register}
              label="time_limit"
              error={errors.time_limit}
              options={{
                required: "TimeShift is required"
              }}
              prefix="Time Limit"
              placeholder="Time Limit"
            />
            <CustomImageFileInput register={register}
              label="course_image"
              options={{
                required: "Course Image is required"
              }}
              placeholder="Course Image"
              error={errors?.course_image} />
            <CustomButton type="submit" text="Add Course" />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CourseAdd;
