import Footer from '../components/footer/Footer';
import DashboardHeader from '../components/dashboard-header/DashboardHeader';
import DashboardSidebar from '../components/dashboard-sidebar/DashboardSidebar';

import Overview from '../components/Instructer/Overview';
import CoursesList from '../components/Instructer/CourseList';
import { useEffect, useState } from 'react';
import { Route, Routes, Navigate, redirect, Outlet } from 'react-router-dom';
import UserPermissionList from '../components/acl/UserPermissionList';
import UsersList from '../components/acl/user/UserList';
import GroupPermissionList from '../components/acl/group-permission/GroupPermissionList';
import GroupList from '../components/acl/group-permission/GroupList';
import CategoryContainer from './Category';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => Math.random() * 1000),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

function InstructorDashboard() {
  const [pathname, setPathName] = useState('');
  const [visibleMenuList, setVisibleMenuList] = useState(false);

  useEffect(() => {}, [window.location.pathname]);

  return (
    <>
      <DashboardHeader />
      <div className="flex">
        <DashboardSidebar setVisibleMenuList={setVisibleMenuList} visibleMenuList={visibleMenuList} setPathName={setPathName} />

        <div className={`w-full h-full`}>
          <Outlet context={ visibleMenuList } />
          {(pathname === '/instructor_dashboard/' || pathname === '/instructor_dashboard')  && <CoursesList />}
        </div>
      </div>
      <Footer />
    </>
  );
}
export default InstructorDashboard;
