import { FiFileText, FiList, FiSettings, FiUserCheck } from 'react-icons/fi';
import { MenuItem } from '../../types/MenuItems';
import { checkIfAnyExist } from '../../utils/array';
import useTranslation from '../../utils/translation';
import useAuthorization from '../auth/authorization';

export interface DashboardMenuItem extends MenuItem {
  id: string;
  name: string;
  children?: DashboardMenuItem[];
  active?: boolean;
  link: string;
  icon?: any;
  visible?: boolean;
  keyId: number;
}
const useDashboardMenuService = () => {
  const { translate } = useTranslation();
  const authorization = useAuthorization();

  const menuList: DashboardMenuItem[] = [
    {
      id: 'all_courses',
      keyId: 1,
      name: translate('all_courses'),
      link: '#',
      icon: <FiList></FiList>,
      children: [
        {
          id: 'category',
          keyId: 13,
          name: 'Category',
          link: '/instructor_dashboard/category',
        },
        {
          id: 'single_courses',
          keyId: 11,
          name: 'Courses',
          link: '/instructor_dashboard/allcourse',
        },
        {
          id: 'detail_course',
          keyId: 12,
          name: 'Course detail',
          link: '/courses_detail',
        },
      ],
    },
    {
      id: 'acl',
      keyId: 2,
      name: translate('acl'),
      link: '#',
      icon: <FiUserCheck></FiUserCheck>,
      children: [
        {
          id: 'list_user',
          keyId: 21,
          name: 'Users',
          link: '/instructor_dashboard/user_list',
          // visible: authorization.canRead('course'),
        },
        {
          id: 'permission_user',
          keyId: 22,
          name: 'User Permission',
          link: '/instructor_dashboard/user_permission_list',
        },
        {
          id: 'group',
          keyId: 23,
          name: 'Group',
          link: '/instructor_dashboard/group_list',
        },
        {
          id: 'group_permission',
          keyId: 24,
          name: 'Group Permission',
          link: '/instructor_dashboard/group_permission_list',
        },
      ],
    },
    {
      id: 'setting',
      keyId: 31,
      name: translate('setting'),
      link: '#',
      icon: <FiSettings></FiSettings>,
    },
  ];
  menuList.forEach((menu) => {
    menu.visible =
      menu.children && checkIfAnyExist(menu.children, 'visible', true);
  });

  return { menuList };
};

export default useDashboardMenuService;
