import React, { useRef, useState } from "react";
import { FieldValues, Path, PathValue } from "react-hook-form";
import { InputProps } from "../../types/InputTypeProp";
import CustomInputError from "../custom-input-error/CustomInputError";
import { FileTypes } from "../../types/Enums";

function CustomImageFileInput<T extends FieldValues>({fileType = 100,label, placeholder, register, options, error}: InputProps<T>){

  const [value, setValue] = useState<FileList>()

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setValue(event.target.files)
    }
  };

  return (
    <>
    <div className="flex items-center justify-center w-full mt-5">
      <label
        htmlFor="dropzone-file"
        className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
      >
        {value ? 
        <img className="h-full max-w-full" src={URL.createObjectURL(value[0])} alt="preview image" />
//         <video className="h-full max-w-full" ref={ref} controls >
//   <source src={value}  type="video/mp4" />
// Your browser does not support the video tag.
//     </video>
        :  <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            aria-hidden="true"
            className="w-10 h-10 mb-3 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            ></path>
          </svg>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span>
          </p>
        </div>}
        <input
          {...register(label, {...options, onChange: (e) => handleFile(e)})}
          id="dropzone-file"
          type="file"
          className="hidden"
          accept={FileTypes[fileType]}
        />
      </label>
    </div>
    <CustomInputError msg={error?.message} />
    </>
  );
}

export default CustomImageFileInput;
