import shape21 from "../../assets/images/shape-21.webp";
import logo from "../../assets/images/logo.png";
import shape22 from "../../assets/images/shape-22.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope,faPhoneAlt,faPhoneFlip } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="bg-custom_black-200  ">
            <div className="bg-[#E7F8EE] w-full ">
                <div className="pt-[30px] pb-[80px] relative w-full flex ">
                    {/* <img className=" absolute top-[80px] left-[5%] z-[-1] animate-down max-w-full" src={shape21} alt="Shape" /> */}
         
                        <div className="flex md:justify-around   flex-wrap mt-[50px] w-full">
                            <div className="px-5  m-auto sm:m-0 w-[300px] sm:w-[200]">
                                <div className="grid grid-rows-4 grid-flow-col">
                                    <div className="widget-logo">
                                        <a href="#"><img src={logo} alt="Logo" className="w-20" /></a>
                                    </div>

                                    <div className="mt-[10px]">
                                        <h4 className="text-[#212832] font-medium text-[22px] mb-0">Ethiopia</h4>
                                        <p className="text-[#309309] font-[400] text-[15px] mt-[6px]">Bole, Addis Ababa.</p>
                                    </div>

                                    <ul className="pt-[25px]">
                                        <li>
                                            <p className="font-light"> <FontAwesomeIcon icon={faEnvelope} className="text-custom_green-900" /> <a href="mailto:address@gmail.com">address@gmail.com</a> </p>
                                        </li>
                                        <li>
                                            <p className="font-light"><FontAwesomeIcon icon={faPhoneAlt} className="text-custom_green-900 rotate-90" /> <a href="tel:9702621413">(251)9 262-1413</a> </p>
                                        </li>
                                    </ul>

                                    <ul className="flex pt-[20px]">
                                    <li className="mt-[12px]"><a href="#"><i className="flaticon-facebook"></i></a></li>
                                    <li className="mt-[12px]"><a href="#"><i className="flaticon-twitter"></i></a></li>
                                    <li className="mt-[12px]"><a href="#"><i className="flaticon-skype"></i></a></li>
                                    <li className="mt-[12px]"><a href="#"><i className="flaticon-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="px-5 ">

                                <h4 className="text-[#212832] font-medium text-[22px] mb-0">Category</h4>

                                <ul className="pt-[36px] font-[300]">
                                   <li className="mt-[12px]"><a href="#">Creative Writing</a></li>
                                   <li className="mt-[12px]"><a href="#">Film &amp; Video</a></li>
                                   <li className="mt-[12px]"><a href="#">Graphic Design</a></li>
                                   <li className="mt-[12px]"><a href="#">UI/UX Design</a></li>
                                   <li className="mt-[12px]"><a href="#">Business Analytics</a></li>
                                   <li className="mt-[12px]"><a href="#">Marketing</a></li>
                                </ul>
                            </div>
                            <div className=" px-5 ">
                                <h4 className="text-[#212832] font-medium text-[22px] mb-0">Quick Links</h4>

                                <ul className="pt-[36px] font-[300]">
                                    <li className="mt-[12px]"><a href="#">Privacy Policy</a></li>
                                    <li className="mt-[12px]"><a href="#">Discussion</a></li>
                                    <li className="mt-[12px]"><a href="#">Terms &amp; Conditions</a></li>
                                    <li className="mt-[12px]"><a href="#">Customer Support</a></li>
                                    <li className="mt-[12px]"><a href="#">Course FAQ’s</a></li>
                                </ul>
                            </div>
                            <div className="px-5  mt-10 sm:mt-0  ">
                                <h4 className="text-[#212832] font-medium text-[22px] mb-0">Subscribe</h4>

                                <div className="pt-[36px] ">
                                    <p className="mt-[12px] max-w-[250px] font-[300] pb-3">Lorem Ipsum has been them an industry printer took a galley make book.</p>

                                    <div className="widget-form">
                                        <form action="#" className="flex flex-col justify-start">
                                            <input type="text" placeholder="Email here"  className="w-full max-w-[250px] h-[55px] py-0 px-[30px] border-[1px] border-solid bg-white text-[#212832] font-[15px] font-medium outline-none rounded-[16px]"/>
                                            <button className="bg-custom_green-900 w-[150px] h-[40px] rounded-[7px] mt-2 text-white  font-smal font-medium btn:hover-dark">Subscribe Now</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>                       
                    </div>

                    <img className="absolute bottom-[95px] right-[3%] z-[-1] animate-left" src={shape22} alt="Shape" />

             </div>
       

            <div className="container  w-full">
                <div className="flex justify-between  w-full flex-wrap items-center py-7 px-4 text-sm">
                    <ul className="flex space-x-4 items-center flex-wrap   mb-5 md:mb-0 ">
                        <Link to={"/"} className="text-white no-underline ">Terms of Service </Link>
                        <Link to={"/"} className="text-white no-underline">* Policy PrivLinkcy </Link>
                        <Link to={"/"} className="text-white no-underline">* Sitemap </Link>
                        <Link to={"/"} className="text-white no-underline">* Security</Link>

                    </ul>
                    <ul className="flex justify-between">
                        <p className="text-base text-white font-normal flex items-start">
                            <Link to={"/"} className="text-white no-underline">
                                © 2021 eLearning Made with by GeezTech
                            </Link>
                        </p>

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;