import { RefreshToken } from "../types/UserItem";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router-dom";
import useToast from "../components/toast/ToastHook";
import {PRODUCTION_ENVIRONMENT, DEVELOPMENT_API_URL, PRODUCTION_API_URL} from "./environments/environment";
export let host: string;
if(PRODUCTION_ENVIRONMENT){
      host = PRODUCTION_API_URL
  }else{
      host = DEVELOPMENT_API_URL
  }
export const commonURL = `${host}/commons/`;
export const appURL = `${host}/applications/`;

export const tokenKey = `${appURL}.token.authorizationData`;
export const refreshKey = `${appURL}.refresh.authorizationData`;

const useApi = () => {

  const navigate = useNavigate()
  const toast = useToast()

  const addRefreshToken = async (error: AxiosError, cb: Function) => {

    const config = error.config;

    try {
      let res = await refresh_token();
      if (res.data.access) {
        localStorage.setItem(tokenKey, res.data.access);
        localStorage.setItem(refreshKey, res.data.refresh);
        config!.headers = { ...config?.headers, Authorization: `Bearer ${res.data.access}` }
        token = res.data.access
      }
      return cb(config);
    } catch (err) {
      const errors = err as Error | AxiosError;
      if (axios.isAxiosError(errors)) {
        const status = errors.response?.status
        if (status == 400 || status == 401) {
          localStorage.removeItem(tokenKey);
          localStorage.removeItem(refreshKey)
          navigate('/login')
        }
      }
      return Promise.reject(err);

    }
  }

  const useAppApi = () => {
    const api = axios.create({
      baseURL: appURL,
      headers: {
        'Content-Type': 'application/json',
      }
    })

    api.interceptors.request.use((config: AxiosRequestConfig) => requestConfig(api, config), error => Promise.reject(error))
    api.interceptors.response.use((response) => response, (error) => handleResponseError(error, api))
    return api
  }
  const useCommonApi = () => {

    const api = axios.create({
      baseURL: commonURL,
      headers: {
        'Content-Type': 'application/json',
      }
    })

    api.interceptors.request.use((config: any) => requestConfig(api, config), error => Promise.reject(error))
    api.interceptors.response.use((response) => response, (error) => handleResponseError(error, api))
    return api
  }

  let token = localStorage.getItem(tokenKey);

  const refresh_token = () => {
    const refresh = localStorage.getItem(refreshKey);
    return axios.post<RefreshToken>(`${commonURL}authorize/token/refresh/`, {
      refresh,
    });
  }

  const handleResponseError = (error: AxiosError, cb: Function) => {
    const status = error.response?.status
    switch (status) {
      case 401:
        return addRefreshToken(error, cb);
      case 403:
        toast.alert('access denied')
        // toast.error("Access Denied");
        break;
      case 404:
        // toast.error("Resource Not found" + error.config.url );
        break;
      case 503:
        toast.alert("Unable to contact remote server. Make sure your connection is working and try again");
        break;
      default:
        //handle remaining 4xx or 5xx responses
        // toast.error(ErrorUtils.message(error.response))
        break;
    }
    return Promise.reject(error)
  }

  const getToken = () => {
    return localStorage.getItem(tokenKey);
  }

  const requestConfig = (api: AxiosInstance, config: AxiosRequestConfig) => {
    if (config.url?.endsWith("/null")) {
      return config;
    }
    if (getToken()) {
      config.headers!.Authorization = `Bearer ${token}`;
    } else {
      delete api.defaults.headers.common.Authorization;
    }
    return config;
  }

  const commonApi = useCommonApi()
  const appApi = useAppApi()

  return { appApi, commonApi }

}

export default useApi
