import Advert from "../advert/Advert";
import Navbar from "../navbar/Navbar";
import shape8 from "../../assets/images/shape-8.webp";
import shape23 from "../../assets/images/shape-23.webp";
import shape5 from "../../assets/images/shape-5.webp";
import shape6 from "../../assets/images/shape-6.webp";
import shape24 from "../../assets/images/shape-24.webp";
import author11 from "../../assets/images/author-11.webp";
import BreadCrumb from "../bread-crumb/BreadCrumb";
import { GiAchievement } from "react-icons/gi";
import CustomText from "../custom-text/CustomText";

function Header(props: any) {
  const { title, activeTab, underlined = "Form" } = props;
  return (
    <>
      <div className="relative top-0 left-0 w-full z-50">
        <Advert />
        <Navbar />
      </div>
      {/* <div className="h-[550px] pt-28 items-center bg-custom_green-100 flex relative overflow-hidden w-full">
        <img
          src={shape8}
          alt="shape8"
          className="absolute top-1/3 md:left-24 animate-custom-spin w-16 md:w-fit"
        />
        <img src={shape23} alt="shape23" className="absolute bottom-0 left-0" />
        <div className="container mx-auto">
          <div className="w-96 lg:w-[500px] xl:max-w-lg">
            <BreadCrumb activeTab={activeTab} />
            <h2 className="text-2xl md:text-5xl font-medium mb-0 mt-4 pb-4">
              {title} <CustomText text={underlined} />
            </h2>
          </div>
        </div>
        <div className="absolute left-[62%] top-[30%] sm:left-[57%] md:top-[47%] md:left-[57%]">
          <img
            src={shape5}
            alt=""
            className="absolute -top-4 -right-32 max-w-full animate-custom-wiggle hidden sm:block sm:-right-24"
          />
          <div className="w-20 h-20 md:w-24 md:h-24 lg:w-36 lg:h-36 text-center border-solid border border-custom_green-900 border-opacity-20 rounded-full mx-auto p-3">
            <div className="bg-custom_green-900 flex justify-center items-center w-full h-full rounded-full">
              <i className="text-4xl lg:text-8xl text-white block">
                <GiAchievement />
              </i>
            </div>
            <img src={shape6} alt="" className="pt-2 w-24 md:w-fit" />
          </div>
        </div>
        <img
          src={shape24}
          alt=""
          className="absolute bottom-0 right-0 w-48 md:w-fit"
        />
        <img
          src={author11}
          alt=""
          className="absolute bottom-[6%] w-20 top-auto right-[11%] sm:w-24 sm:bottom-[11%] lg:top-[45%] lg:right-[9%] lg:w-fit rounded-full"
        />
      </div> */}
    </>
  );
}

export default Header;
