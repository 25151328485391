import CustomInput from "../custom-input/CustomInput";
import CustomButton from "../custom-button/CustomButton";
import CustomCard from "../custom-card/CustomCard";
import useChapterService from "./ChapterService";
import { Chapter } from "../../types/Course";
import useLoading from "../loading/LoadingHook";
import Loading from "../loading/Loading";
import useTranslation from "../../utils/translation";
import { SubmitHandler, useForm } from "react-hook-form";
import useToast from "../toast/ToastHook";
import toasts from "../../slicers/toasts";

interface ChapterAddProps {
  course: string;
  reload: () => void
}

function ChapterAdd({course, reload}: ChapterAddProps) {
  
  const chapterService = useChapterService()
  const loading = useLoading()
  const toast = useToast()
  const {translate} = useTranslation()
  const { register, handleSubmit, formState: { errors }, reset } = useForm<Chapter>();

  const onSubmit : SubmitHandler<Chapter> = (data) => {
    loading.startLoading()
    chapterService.addChapter({...data, course})
      .then((value) => {
        reload()
        toast.success("Chapter is added")
        reset()
        loading.stopLoading()
      })
      .catch((error) =>  { 
        toast.warning(error.message)
        loading.stopLoading()});
  };

  return (
    <>
    <Loading {...loading} />
      <CustomCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput placeholder={translate('name')} register={register} label='chapter_name' options={{required: "Chapter Name is required" }} error={errors.chapter_name} />
          <CustomInput placeholder={translate('title')} register={register} label='chapter_title' options={{required: "Chapter Title is required"}} error={errors.chapter_title} />
          <CustomButton type="submit" text="Add Chapter" />
        </form>
      </CustomCard>
    </>
  );
}

export default ChapterAdd;
