import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { sidebars } from "./CourseSideBarMenu";

function CourseSideBar(props: {active: string, hide: boolean, setHide:(action: boolean) => void}) {
  return (
    <>
      <div className={"absolute w-7/12 z-10 bg-white sm:relative sm:block sm:w-4/12 md:w-2/12 border border-custom_green-700 rounded-lg py-3" + (props.hide ? ' hidden ' : ' block ')}>
      <a className="flex flex-col items-end pr-2 p-0 ml-auto sm:hidden " onClick={() => props.setHide(true)}>
          <i className="text-lg cursor-pointer"><FaTimes /></i>
      </a>
        {sidebars.map((sidebar) => {
          return (
            <div key={sidebar.name} className={"group flex mx-3 mt-2 items-center" + (props.active == sidebar.name ? " text-custom_green-900 ml-0 " : " text-gray-500 hover:text-custom_green-900 hover:ml-0")}>
              <span className={"border-l-4 border-t-2 border-b-2 border-t-transparent border-b-transparent h-6 mr-2 border-custom_green-900 " + (props.active == sidebar.name ? "inline-block" :  " hidden group-hover:inline-block")}></span>
              <i className="mr-3">{sidebar.icon} </i>
              <p className={(props.active == sidebar.name ? "text-custom_black-200  " : "group-hover:text-custom_black-200")}>
                {sidebar.name}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default CourseSideBar;
