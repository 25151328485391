import { useEffect, useState } from "react";
import DashboardHeader from "../components/dashboard-header/DashboardHeader";
import DownloadSection from "../components/download/DownloadSection";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import author11 from "../assets/images/author-11.webp";
import { RegisterOptions, UseFormRegisterReturn, useForm } from "react-hook-form";
import CustomGroupInput from "../components/custom-input/CustomGroupInput"
import CustomButton from "../components/custom-button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../slicers/user";
import { UserLogin } from "../types/UserItem";
import { Languages } from "../types/Translation";
import useTranslation from "../utils/translation";
import CustomDropdown from "../components/custom-input/CustomDropdown"; import CustomInput from "../components/custom-input/CustomInput";
import { BsMailbox } from "react-icons/bs";
import { BsTelephoneFill } from "react-icons/bs";
import { BsCamera } from "react-icons/bs";
import { RootState } from "../slicers/store";
import useAuthorization from "../components/auth/authorization";
import User from "../components/auth/User";
import useUserPages from "../components/auth/UserPages";
import ResetPassword from "../components/auth/ResetPassword";
import useCourseService from "../components/course/CourseService";
import useLoading from "../components/loading/LoadingHook";
import { Course } from "../types/Course";
import { FaClock, FaBookOpen, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
import { SelectItem } from "../types/MenuItems";
import { UserPermission } from "../types/UserPermission";
import PasswordChange from "../components/auth/PasswordChange";

function Profile() {
    const { register,control, handleSubmit, formState: { errors } } = useForm<UserLogin>();
    const { translate } = useTranslation()
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user)
    const authorization = useAuthorization()

    const [selectedLanguage, setSelectedLanguage] = useState<{ name: string, id: Languages }>();
    const languages: SelectItem<string | number>[] = [
        { label: "Amharic", value: Languages.AMH },
        { label: Languages.ENG, value: "English" },
    ];

    const applyLanguage = (language: { name: string, id: Languages }) => {
        setSelectedLanguage(language)
        dispatch(setLanguage(language.id));
    }
    const UserPages = useUserPages();
    const courseService = useCourseService();
    const [courses,setCourses] = useState<Course[]>([]);

    const loading = useLoading()
    useEffect( () => 
    {
        loading.startLoading()
        courseService.getCourses({filterText: '', category_id: undefined}).then(
        ({data: courses}) => {
            setCourses(courses)
            loading.stopLoading()
        }
    ).catch(error => loading.stopLoading())}
    , [])
    
   const courseDetailUrl=(id:string)=>{
    return "/courses_detail/"+id+"/video";
    }
    return (

        <>
           <DashboardHeader className="md:flex-1" />
             <div>
                <div >
                    <div className="h-[250px] w-full bg-[#212832] relative">
                        <div className="absolute bottom-5  right-5 text-white align-right">
                            <p className="flex justify-end"> {user.email}  <BsMailbox size={25} className="pl-2" /></p>
                            <p className="flex justify-end"> {""} <BsTelephoneFill size={25} className="pl-2" /> </p>
                            <div className="h-5"></div>
                        </div>
                    </div>


                </div>
                <div className="flex flex-col lg:flex-row md:flex-col" >
                    <div className="mt-[-75px] md:ml-0 lg:ml-[200px] z-auto relative bg-transparent shadow-sm flex flex-col justify-center place-items-center">
                        <div className=" rounded-full p-2 h-20 w-20 bg-white items-center">
                        <img src={user.profile_pic} alt="" className=" h-10 w-20  rounded-full" />

                        </div>
                        <div className="text-center mt-5 leading-10">
                            <p className="text-xl">{user.first_name}{user.last_name}</p>
                            <p className="text-lg">@{user.username}</p>
                            <p className="text-lg text-custom_green-900 font-bold">{""}</p>
                        </div>

                        <div className="sm:mt-[5%] md:mt-[5%] lg:mt-[5%] mb-4 ">
                            <CustomDropdown
                                placeholder={translate("languages")}
                                data={languages}
                                val={selectedLanguage}
                                onValueChange={applyLanguage}
                                id="id"
                                label="username"
                                control={control}
                                isSearchable={false} 
                                register={register}                            />

                        </div>
                        <div className="border-[#E7F8EE] border-[1px] border-solid  py-5 px-8 rounded-md">
                        <PasswordChange/>
                        </div>
                    </div>
                    <div className="min-h-[500px] w-full p-[1%] flex flex-wrap justify-evenly">
                        <div className="border-[#E7F8EE] border-[1px] border-solid flex flex-wrap  rounded-md gap-3 relative lg:flex-row md:flex-row justify-center  pb-10">
                        {courses?.map(course=><>
                            <Link to={courseDetailUrl(course.id)} className="flex-col border-[#E7F8EE] border-[1px] w-[200px] h-[150px] bg-slate-100 flex justify-center  place-items-center relative mb-2 p-[3px]">
                                    <img src={course.course_image as string} className="h-[150px]" alt={course.name} />
                                        <p className="mt-[-0.5em] p-2 bg-white text-center text-custom_green-900 text-xs rounded-t-lg">
                                            {course.name}
                                        </p>
                                </Link>
                                   </>)}
                         
                            <div className="absolute bottom-0 right-1/2 ">
                                <div className="flex justify-center">
                                    <p className="p-3 bg-custom_green-800 bg-opacity-20 text-center text-sm rounded-t-lg">
                                        Courses Given
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Profile;
