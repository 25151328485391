import { useState } from "react";
import { Category } from "../../types/Category";
import useToast from "../toast/ToastHook";
import useCategoryService from "./CategoryService"

const useCategoryApi = () => {
  const [next,setNext]=useState("")
  const [prev,setPrev]=useState("")
 const {getCategories,deleteCategory,addCategory} = useCategoryService();
 const toast = useToast()
// const addCat =(category: FormData,setCategoriesList:(lst:Category[])=>void)=>{
//   addCategory(category).then(({data: response})=>{
//     getCat({pageSize:5,cursor:"", searchText: "", setCategoriesList})
//   }).catch(()=>{

//   })
// }
 const getCat = (data: { pageSize:number,cursor:string, searchText: string,  setCategoriesList:(lst: Category[]) => void}) => {
  if(data.cursor=="1" ){
    data.cursor=next;
  }else if(data.cursor=="2"){
    data["cursor"]=prev;
  }else{
    data.cursor = next
  }
   getCategories(data).then(({data: response}) => {
    data.setCategoriesList(response.results)
    if(response?.next!==null){
      setNext(response.next["cursor"]);
     }
      if(response?.prev!==null){
        setPrev(response.prev["cursor"]);
       
      }
      
     
    })
 };
 const deleteCat = async(data:{id: string,
  setOpenModel:(id:string)=>void,
  setCategoriesList:(lst: Category[]) => void}) => {
  let result 
  try {
    
     result =  await deleteCategory(data.id);
    getCat({pageSize:5,cursor:"", searchText: "", setCategoriesList:data.setCategoriesList})
    toast.success("Category is deleted")
    data.setOpenModel("")
   } catch (error:any) {
    console.log(error)
    data.setOpenModel("")
    toast.warning(error.message);
    result = error
   
   }
 
  return result
};

 return {getCat,deleteCat}
}

 export default useCategoryApi ;