function CustomButton(props: any) {
  const {
    text,
    form = "",
    type = "button",
    disabled = false,
    fun = () => {},
  } = props;
  return (
    <button
      onClick={fun}
      disabled={disabled}
      type={type}
      className={
        "relative overflow-hidden w-full cursor-pointer mt-5 leading-[3.75rem] rounded-xl text-sm sm:text-lg sm:leading-[3.75rem] font-medium transition-all duration-300 ease-in inline-block px-8 whitespace-nowrap " +
        (form == "" &&
          " text-white bg-custom_green-900 border-custom_green-900 disabled:cursor-not-allowed disabled:opacity-75 hover:opacity-75 ") +
        (form == "transparent" &&
          " bg-custom_light_green text-custom_green-900 hover:text-white hover:bg-custom_green-900 ") +
        (form == "edge" &&
          " bg-white font-normal hover:border hover:text-custom_green-900 hover:border-custom_green-900") +
        (form == "edge-transparent" &&
          " bg-white font-normal hover:border hover:text-white hover:bg-custom_green-900")
      }
    >
      
      {text}
    </button>
  );
}

export default CustomButton;
