import DownloadSection from '../components/download/DownloadSection';
import Header from '../components/header/Header';
import about from '../assets/images/about.webp';
import circle from '../assets/images/circle-shape.webp';
import CustomButton from '../components/custom-button/CustomButton';
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  FiUser,
} from 'react-icons/fi';
import author11 from '../assets/images/author-11.webp';
import { FiPlay } from 'react-icons/fi';
import { GiDuration, GiLevelEndFlag } from 'react-icons/gi';
import { BsFilePersonFill } from 'react-icons/bs';
import { MdOndemandVideo } from 'react-icons/md';
import { FaBook } from 'react-icons/fa';
import { TbCertificate } from 'react-icons/tb';
import Footer from '../components/footer/Footer';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import useTranslation from '../utils/translation';
import useCourseService from '../components/course/CourseService';
import useLoading from '../components/loading/LoadingHook';
import { useParams } from 'react-router-dom';
import { Course, CourseEnrolled } from '../types/Course';
import { Instructor } from '../components/Instructer/Instructor';
import { Reviewers } from '../components/Reviewers/Reviewers';
import useEnrollmentService from '../components/course/EnrollmentService';
import { RootState } from '../slicers/store';
import { useSelector } from 'react-redux';

function CourseDescription({ description }: { description: any }) {
  const { translate } = useTranslation();
  return (
    <div className="mb-4">
      <h3 className="text-2xl font-medium text-custom_black mb-0 mt-6">
        {translate('description')}:
      </h3>
      <p className="text-gray-500 mt-4">{description}</p>

      <h3 className="text-2xl font-medium text-custom_black mb-0 mt-6">
        Curriculum:
      </h3>
      <p className="text-gray-500 mt-4">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
        veniam maxime ut dolor, quisquam aliquam. Eius, sed! Ipsa adipisci
        cumque, quisquam reiciendis tempora, quibusdam molestiae rem nam
        deleniti, labore minus?
      </p>
      <h3 className="text-2xl font-medium text-custom_black mb-0 mt-6">
        Certification:
      </h3>
      <p className="text-gray-500 mt-4">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates
        veniam maxime ut dolor, quisquam aliquam. Eius, sed! Ipsa adipisci
        cumque, quisquam reiciendis tempora, quibusdam molestiae rem nam
        deleniti, labore minus?
      </p>
    </div>
  );
}

function CourseDetail() {
  const [tab, setTab] = useState('description');
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const courseService = useCourseService();
  const enrollmentService = useEnrollmentService();
  const loading = useLoading();
  const [course, setCourse] = useState<CourseEnrolled>();
  const user = useSelector((state: RootState) => state.user);
  let { id } = useParams();
  let courseDetail = [];

  const tabs: { [id: string]: JSX.Element } = {
    description: <CourseDescription description={course?.description} />,
    instructor: <Instructor instructors={course?.instructor} />,
    reviews: <Reviewers id={id} />,
  };

  const changeTab = (selTab: string) => {
    setTab(selTab);
  };

  const handleEnrollment = () => {
    const data = { user: user.id, course: course?.id };
    if(course?.is_enrolled){
        return navigate(`/courses_detail/${id}/video`)
    }else{
      enrollmentService.addCourseEnrollment(data).then((data) => {
        if (data) {
          return navigate(`/courses_detail/${id}/video`);
        } else {
          return navigate(`/courses_detail/${id}`);
        }
      });  
    }
    
  };

  useEffect(() => {
    console.log(id);
    //loading.startLoading()
    courseService
      .getEnrolledCourse(id ? id : '')
      .then(({ data: courses }) => {
        setCourse(courses);
        loading.stopLoading();
      })
      .catch((error) => loading.stopLoading());
  }, [id]);

  return (
    <>
      <Header title="Courses" underlined="Details" activeTab="courses_detail" />
      <div className="container mx-auto pt-20 flex flex-wrap flex-col xl:flex-row items-start">
        <div className="relative overflow-hidden basis-full lg:basis-7/12">
          <div className="relative overflow-hidden">
            <img
              src={course?.course_image as string}
              alt={translate('about')}
              className="w-full rounded-xl"
            />
            <span className="absolute bottom-5 left-5 bg-orange-400 text-sm px-6 rounded-md h-9 inline-block text-white leading-9">
              {course?.category}
            </span>
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <img src={circle} alt="cirle" />
              <a
                href="https://www.youtube.com/watch?v=Wif4ZkwC0AM"
                className="absolute top-[52%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white"
              >
                <FiPlay></FiPlay>
              </a>
            </div>
          </div>
          <h2 className="text-2xl font-medium mt-7 text-custom_black leading-6">
            {course?.name}
          </h2>
          <div className="flex items-center pt-2 justify-between">
            <div className="flex items-center mt-2">
              <img src={author11} alt="Author" className="w-12 rounded-full" />
            </div>
            <div className="flex-1 pl-4 pb-1">
              {course?.instructor.map((data) => (
                <a key={data['id']}
                  href={data['profile_pic']}
                  className="text-lg font-medium text-custom_black inline-block leading-5 relative
                 after:content-['||'] after:text-custom_green-900 after:text-2xl after:font-light after:relative after:m-4 after:-bottom-1"
                >
                  {data['first_name']}
                </a>
              ))}
              <span className="text-sm font-normal text-custom_green-900 inline-block leading-6">
                {course?.enrolled_count} Enrolled Students
              </span>
            </div>
            <div className="flex items-center mt-2">
              <span className="text-sm font-medium text-custom_black mr-1">
                4.9
              </span>
            </div>
          </div>
          <div className="pt-7">
            <div className="bg-custom_light_green rounded-lg p-4 pt-1">
              <ul className="flex justify-center">
                <li className="p-2 pb-0">
                  <CustomButton
                    text={translate('description')}
                    fun={() => changeTab('description')}
                    form={tab !== 'description' ? 'edge-transparent' : ''}
                  />
                </li>
                <li className="p-2 pb-0">
                  <CustomButton
                    text="Instructor"
                    fun={() => changeTab('instructor')}
                    form={tab !== 'instructor' ? 'edge-transparent' : ''}
                  />
                </li>
                <li className="p-2 pb-0">
                  <CustomButton
                    text="Reviews"
                    fun={() => changeTab('reviews')}
                    form={tab !== 'reviews' ? 'edge-transparent' : ''}
                  />
                </li>
              </ul>
            </div>
            {tabs[tab]}
          </div>
        </div>

        <div className="lg:basis-5/12">
          <div className="w-11/12 ml-auto">
            <div className="bg-custom_light_green p-10 border border-custom_green-900 rounded-xl">
              <h1 className="text-3xl font-bold text-custom_green-900 text-center">
                {course?.course_price} Birr
              </h1>
              <ul className="border-t mt-9">
                {/* <li className="border-b py-4">
                  <i className="text-lg mr-1 text-custom_green-900 inline-block">
                    <BsFilePersonFill></BsFilePersonFill>
                  </i>
                  <strong className="text-lg font-medium text-custom_black">
                    Instructor
                  </strong>
                  <span className="block float-right font-normal text-lg text-gray-500">
                    {course?.instructor[0]["first_name"]}
                  </span>
                </li> */}
                <li className="border-b py-4">
                  <i className="text-lg mr-1 text-custom_green-900 inline-block">
                    <GiDuration></GiDuration>
                  </i>
                  <strong className="text-lg font-medium text-custom_black">
                    Duration
                  </strong>
                  <span className="block float-right font-normal text-lg text-gray-500">
                    08 hr 15 mins
                  </span>
                </li>
                <li className="border-b py-4">
                  <i className="text-lg mr-1 text-custom_green-900 inline-block">
                    <MdOndemandVideo></MdOndemandVideo>
                  </i>
                  <strong className="text-lg font-medium text-custom_black">
                    Lectures
                  </strong>
                  <span className="block float-right font-normal text-lg text-gray-500">
                    29
                  </span>
                </li>
                <li className="border-b py-4">
                  <i className="text-lg mr-1 text-custom_green-900 inline-block">
                    <GiLevelEndFlag></GiLevelEndFlag>
                  </i>
                  <strong className="text-lg font-medium text-custom_black">
                    Level
                  </strong>
                  <span className="block float-right font-normal text-lg text-gray-500">
                    {course?.course_level}
                  </span>
                </li>
                <li className="border-b py-4">
                  <i className="text-lg mr-1 text-custom_green-900 inline-block">
                    <FaBook></FaBook>
                  </i>
                  <strong className="text-lg font-medium text-custom_black">
                    Language
                  </strong>
                  <span className="block float-right font-normal text-lg text-gray-500">
                    English
                  </span>
                </li>
                <li className="border-b py-4">
                  <i className="text-lg mr-1 text-custom_green-900 inline-block">
                    <TbCertificate></TbCertificate>
                  </i>
                  <strong className="text-lg font-medium text-custom_black">
                    Certificate
                  </strong>
                  <span className="block float-right font-normal text-lg text-gray-500">
                    Yes
                  </span>
                </li>
              </ul>
              <CustomButton
                text={course?.is_enrolled?'Go To Course':'Enroll Now'}
                fun={handleEnrollment}
              />
            </div>
            <div className="mt-12">
              <h4 className="text-2xl font-medium -mt-2 mb-0">Share Course:</h4>
              <ul className="flex justify-between mt-6 flex-wrap">
                <li className="pt-2 mr-2">
                  <a
                    href="$"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
              flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiFacebook></FiFacebook>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href="$"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
              flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiLinkedin></FiLinkedin>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href="$"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
              flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiTwitter></FiTwitter>
                    </i>
                  </a>
                </li>
                <li className="pt-2 mr-2">
                  <a
                    href="$"
                    className="w-12 h-12 leading-[52px] border border-custom_light_green rounded-lg
              flex items-center justify-center text-lg text-custom_black transition-all duration-300 ease-in"
                  >
                    <i>
                      <FiInstagram></FiInstagram>
                    </i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <DownloadSection />
      <Footer />
    </>
  );
}

export default CourseDetail;
