import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import CourseAdd from "../components/course/CourseAdd";
import useTranslation from "../utils/translation";

function CourseAddPage() {
  const {translate} = useTranslation()
  return (
    <>
      <Header title="Add" activeTab="home" underlined={translate('courses')} />
      <CourseAdd />
      <Footer />
    </>
  );
}

export default CourseAddPage;
