import { MenuItem } from '../../types/MenuItems';
import LinkGenerator from '../custom-link/CustomLink';

const DashboardSubMenu = (props: { subMenu: MenuItem[] }) => {
  
  if (props) {
    return (
      <div className="py-10 px-7 bg-custom_green-100 flex flex-col ">
        {props?.subMenu.map((value: any) => {
          return (
            <div key={value.keyId}>
              <LinkGenerator name={value.name} url={value.link} />
              {value.children && <DashboardSubMenu subMenu={value.children} />}
            </div>
          );
        })}
      </div>
    );
  } else {
    return <></>;
  }
};

export default DashboardSubMenu;
