import React from "react";
import { FieldValues } from "react-hook-form";
import { InputProps } from "../../types/InputTypeProp";
import CustomInputError from "../custom-input-error/CustomInputError";

function CustomGroupInput<T extends FieldValues>({type = 'text', label, prefix, suffix, placeholder, register, options, error,onValueChange }: InputProps<T>) {

    return (<div className="flex flex-col"><div className="flex h-12 mt-5 w-full">
    {(prefix && <span className="inline-flex items-center text-right px-3 text-sm text-custom_black-200 bg-custom_green-200 border border-r-0 border-custom_green-700 rounded-l-md">
      {prefix}
    </span>)}
    <input type={type} {...register(label, options)} onChange={onValueChange} className={"focus:outline-custom_green-900 rounded-none  border flex-grow text-custom_black-200 block flex-4 min-w-0 text-base border-custom_green-700 p-2.5 " + (prefix ? 'rounded-r-lg' : suffix ? 'rounded-l-lg' : 'rounded-lg')} placeholder={placeholder} />
   {(suffix && <span className="inline-flex items-center text-right px-3 text-sm text-custom_black-200 bg-custom_green-200 border border-l-0  border-custom_green-700 rounded-r-md">
      {suffix}
    </span>)}
  </div>
  <CustomInputError msg={error?.message} />
  </div>);
}

export default CustomGroupInput;