import useApi from '../../utils/api';
import { FetchData } from '../../types/FetchData';
import { Course, CourseEnrollment } from '../../types/Course';

const useEnrollmentService = () => {
  const { appApi } = useApi();

  const addCourseEnrollment = (enroll: {}) => {
    const response = appApi.post<CourseEnrollment>('/course/enroll/', enroll, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    console.log('response', response);
    return response;
  };

  return { addCourseEnrollment };
};

export default useEnrollmentService;
