import useApi from "../../utils/api";
import { FetchData } from "../../types/FetchData";
import { Chapter } from "../../types/Course";
import useLoading from "../loading/LoadingHook";

const useChapterService = () => {
  const { appApi } = useApi();
  const loading = useLoading()

  const getChapter = (id: string) => {
    const response = appApi.get<Chapter>(`chapters/${id}/`);
    return response;
  };

  const addChapter = (Chapter: Chapter) => {
    const response = appApi.post<Chapter>("chapters/",
      Chapter);
    return response;
  };

  const getChapters = (data: { filterText: string, courseId: string }) => {
    const response = appApi.get<Chapter[]>("chapters/", {
      params: {
        search: data.filterText,
        course_id: data.courseId
      },
    });
    return response;
  };

  const deleteChapter = (id: string) => {
    const response = appApi.delete<Chapter>(`chapters/${id}/`);
    return response;
  };

  return {getChapters, getChapter, addChapter, deleteChapter}
};

export default useChapterService;
