import useApi from '../../utils/api';
import { Content, ContentSideNav, Question } from '../../types/Course';

export const useContentService = () => {
    const {appApi} = useApi()

 const addContent = (content: Content) => {
    const response = appApi.post<Content>(
        'content/', content,
      //   {headers: {
      //     'content-type': 'application/json'
      // }}
    );
    return response;
};

const getContent = (id: string) => {
  const response = appApi.get<ContentSideNav>(`content/${id}/`);
  return response;
};

 const getContents = (chapter_id: string) => {
    const response = appApi.get<Content[]>(
        'content/',
        {
          params: {
            chapter_id
          }
        }
    );
    return response;
};

const deleteContent = (id: string) => {
  const response = appApi.delete<Question>(`content/${id}/`);
  return response;
};


return {addContent, getContent, getContents, deleteContent}
}
