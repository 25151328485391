import CustomButton from '../components/custom-button/CustomButton';
import { QuestionSideNav } from '../types/Course';

function QuestionList({
  questionsData,
}: {
  questionsData: QuestionSideNav[] | undefined;
}) {
  return (
    <div className=" p-10">
      {questionsData ? (
        questionsData.map((question,index) => (
          <ul key={question.question} >
            <li className='mb-4'>
              <strong className="text-[16px] mb-7">{index+1}. {question.question}</strong>
              {question?.options_questions.map((option) => (
                <ul className='ml-4'>
                  <li className='mb-3 text-sm'>
                    <input type="radio" className="p-5" />
                    <span className="ml-3">{option.value}</span>
                  </li>
                </ul>
              ))}
            </li>
          </ul>
        ))
      ) : (
        <h1>There is no any questions here</h1>
      )}
    </div>
  );
}

export default QuestionList;
