import { useEffect } from "react";
import CustomCard from "../custom-card/CustomCard";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoMdSave} from 'react-icons/io'
import Carousel from "../carousel/CustomCarousel";
import useCarousel from "../carousel/carouselHook";
import QuestionForm from "./QuestionForm";
import CustomDropdown from "../custom-input/CustomDropdown";
import { Question } from "../../types/Course";
import CustomGroupInput from "../custom-input/CustomGroupInput";
import CustomText from "../custom-text/CustomText";

interface QuestionAddProps{
  content: string
}

function QuestionAdd({content}: QuestionAddProps) {
  const carousel = useCarousel(1, false)

  useEffect(() => {
    carousel.updateChildren(Array(1).fill("hi"), () => <QuestionForm content={content} />)
  }, [carousel.width])

  return (
    <>

    <h1 className="font-bold">Content <CustomText text="Questions" /></h1>
    <div className="mt-5">
      <CustomCard>
        <div className="flex gap-x-2">

        {<button className={`${carousel.canPrev ? '' : 'invisible cursor-default'}`} onClick={carousel.movePrev}><i><AiOutlineArrowLeft></AiOutlineArrowLeft></i></button>}
        {<button className={`${carousel.canNext ? '' : 'invisible cursor-default'}`} onClick={carousel.moveNext}><i><AiOutlineArrowRight></AiOutlineArrowRight></i></button>}
        </div>
        <Carousel {...carousel} />
      </CustomCard>
      </div>
    </>
  );
}

export default QuestionAdd;
