import CustomInput from "../custom-input/CustomInput";
import CustomButton from "../custom-button/CustomButton";
import { useUserService } from "./UserService";
import useLoading from "../loading/LoadingHook";
import Loading from "../loading/Loading";
import useTranslation from "../../utils/translation";
import { FieldValues, RegisterOptions, UseFormRegisterReturn } from "react-hook-form";
import { UserRegister } from "../../types/UserItem";
import { SubmitHandler, useForm } from "react-hook-form";
import { ResetPasswordForm } from "../../types/UserItem";
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from "react";
import CustomCard from "../custom-card/CustomCard";

function ResetPassword(props: { isRequest: boolean; }) {
    const { register, setError, handleSubmit, formState: { errors } } = useForm<ResetPasswordForm>();
    const [isLinkSent, setLinkSent] = useState(true)
    const userService = useUserService()
    const loading = useLoading()
    const navigator = useNavigate();
    const {translate} = useTranslation()
    const {uuid, token} = useParams()
    const onSubmit: SubmitHandler<ResetPasswordForm>  = (data) => {
        loading.startLoading()
        if(props.isRequest){
            userService.resetPassword(data.email).then(
                ({data: value}) => {
                    loading.stopLoading()
                    if (value.refresh_token) {
                        // localStorage.setItem(`${baseURL}.authorizationData`, value.access_token);
                        // dispatch(getUser(value.user));
                    }
                    setLinkSent(false)
                }
            ).catch(error => loading.stopLoading());
        }else{
             userService.resetPasswordConfirm(data,uuid, token ).then(
                ({data: value}) => {
                    loading.stopLoading()
                    
                    navigator('/login')
                }
            ).catch(error => {loading.stopLoading();navigator('/login')});
        }
    };
    

    return <>
    <Loading {...loading} />
        {
        isLinkSent?
            <form onSubmit={handleSubmit(onSubmit)}>
                {props.isRequest ?
                    <CustomInput type="text" placeholder={translate('email')} register={register} label="email" options={{
                        required: "Email is required"
                    }}  error={errors.email}/>
                    
                    :
                    <>
                        <CustomInput type="password" placeholder={translate('password')} register={register} label="new_password1" options={{
                        required: "Password is required"
                    }}  error={errors.new_password1} />
                        <CustomInput type="password" placeholder="Confirm Password" register={register} label="new_password2" options={{
                        required: "Confirm Pssword is required"
                    }} error={errors.new_password2}  />
                    </>
                }
                <div className="mt-5">
                    {props.isRequest ? <CustomButton text="Reset" type="submit" /> : <CustomButton text={translate("reset_password") }type="submit" />}
                </div>
            </form>
            :
            <div className={"border border-l-8 border-l-custom_green-900 text-center py-6 h-20   text-custom_green-900 border-solid border-custom_green-100" + 
            "transition-all duration-300 ease-in group hover:border-custom_green-100 bg-custom_green-100  hover:border-l-custom_green-900"
}>
Password reset link is sent to your email
</div>
        }
    </>;
}

export default ResetPassword;