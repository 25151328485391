
import { MenuItem } from "../../types/MenuItems";
import { checkIfAnyExist } from "../../utils/array";
import useTranslation from '../../utils/translation'
import useAuthorization from "../auth/authorization";

const useMenuService = () => {

    const {translate} = useTranslation()
    const authorization = useAuthorization()

    const menuList: MenuItem[] = [
        {
            id: 'home',
            name: translate('home'),
            link: "/",
            visible: true,
        },{
            id: 'courses',
            name: translate('courses'),
            link: '/courses',
            // visible: authorization.canRead('course')
            visible: true
        },
        {
            id: 'all_courses',
            name: translate('all_courses'),
            link: '',
            children: [
                
                {
                    id: 'courseDetail',
                    name: translate('courses_detail'),
                    link: '/courses_detail',
                    // visible: authorization.canRead('course')
                    visible: true
                }
            ]
        },
        {
            id: 'pages',
            name: translate('pages'),
            link: '',
            children: [
                {
                    id: 'login',
                    name: translate("login"),
                    link: "/login",
                    visible: true,
                },
                {
                    id: 'register',
                    name: translate("register"),
                    link: '/register',
                    visible: true
                },
                
              
            ]
        },
        {
            id: 'faq',
            name: translate("faq"),
            link: '/faq',
            visible: true
        },
 
        {
            id: 'blog',
            name: translate('blog'),
            link: "",
            visible: true,
        },{
            id: 'about',
            name: translate("about"),
            link: '/about',
            children:[],
            visible: true
        },
        // {
        //     id: 'course_cart',
        //     name: 'CourseCart',
        //     link: "course_carts",
        //     // visible: authorization.canRead('coursecart')
        //     visible: false
        // },
        { 
            id: 'instructorDashboard',
            name: translate("instructor_dashboard"),
            link: "/instructor_dashboard",
            visible: true,
        },
    ];
    menuList.forEach(menu => {
        menu.visible = menu.children && checkIfAnyExist(menu.children, 'visible', true)
    })

    return {menuList}
}

export default useMenuService
