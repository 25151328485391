import useApi, { host } from "../../utils/api";
import { FetchData } from "../../types/FetchData";
import { Course, CourseEnrolled } from "../../types/Course";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { FetchedApi } from "../../types/FetchedApi";
    // export const host = "http://127.0.0.1:8000";

    export const appURL = `${host}/applications/`;
const useCourseService = () => {
  const { appApi } = useApi();

  
  const getCourse = (id: string) => {
    const response = appApi.get<Course>(`course/${id}`);
    return response;
  };
  const getEnrolledCourse = (id: string) => {
    const response = appApi.get<CourseEnrolled>(`course/${id}`);
    return response;
  };
  const addCourse = (course: Course) => {
    const response = appApi.post<Course>("course/",
      course);
    return response;
  };

  // const getCourses = (data: { filterText: string, category_id:any }) => {
  //   const response = appApi.get<Course[]>("course/", {
  //     params: {
  //       search: data.filterText,
  //       category_id: data.category_id,
  //     },
  //   });
  //   return response;
  // };

  const getCourses = (data: { filterText: string, category_id:any }) => {


    const api = axios.create({
      baseURL: appURL,
      headers: {
        'Content-Type': 'application/json',
      }
    })

    const response = api.get<Course[]>("course/", {
      params: {
        search: data.filterText,
        category_id: data.category_id,
      },
    });
    return response;
  };

  const deleteCourse = (id: string) => {
    const response = appApi.delete<Course>(`course/${id}`);
    return response;
  };

  return {getCourses, getCourse,getEnrolledCourse, addCourse, deleteCourse}
};

export default useCourseService;
