import CustomInput from "../custom-input/CustomInput";
import CustomButton from "../custom-button/CustomButton";
import { useState } from "react";
import { useUserService } from "./UserService";
import { appURL } from "../../utils/api";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AuthUser, BackEndError, UserLogin } from "../../types/UserItem";
import CustomInputError from "../custom-input-error/CustomInputError";
import useLoading from "../loading/LoadingHook";
import Loading from "../loading/Loading";
import { Languages } from "../../types/Translation";
import useTranslation from "../../utils/translation";
import { getUser } from "../../slicers/user";
import { useGoogleLogin } from '@react-oauth/google';
import LoginGithub from 'react-login-github';
import GitHubLogin from 'react-github-login';
import { SubmitHandler, useForm } from "react-hook-form";

function Login() {
    const { register, setError, handleSubmit, formState: { errors } } = useForm<UserLogin>();
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const userService = useUserService()
    const loading = useLoading()
    const {translate} = useTranslation()

    const addToken = (value: AuthUser) => {
        localStorage.setItem(`${appURL}.token.authorizationData`, value.access_token);
                    localStorage.setItem(`${appURL}.refresh.authorizationData`, value.refresh_token)
                    dispatch(getUser({...value.user, lang: Languages.ENG}));
                    loading.stopLoading()
                    navigator('/')
    }

    const onSubmit: SubmitHandler<UserLogin>  = (data) => {
        loading.startLoading()
        userService.login(data).then(
            ({data: value}) => {
                if (value.refresh_token) {
                 addToken(value)   
                 loading.stopLoading()
                }
            }
        ).catch(error =>
            {
                const errors = error.response.data.error.details;
                for (const err in errors){
                    if (err == 'non_field_errors'){
                        setError('root', {message: errors[err]})
                    }else {
                        setError(err as keyof UserLogin, {message: errors[err]})
                    }
                }
                loading.stopLoading()
            });
            
    };


    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            console.log(tokenResponse)
            userService.google(tokenResponse.access_token).then(({data:value}) => {
                addToken(value)  
            })
        },
        onError:error=>{
            console.log(error);
        }
      });
      
      const onSuccess = (e:any) => {
        userService.github(e.code).then(({data:value})=>{
            addToken(value)
        })
      }

      const onFailure =(e:any) => {
        console.log("error:", e)
      }
      
      const loginTelegram = () =>{
        //   userService.telegram().then(({data:value})=>{
        //         // addToken(value)
        //     })
        return <a href="https://t.me/nisir_elearning_bot/elearning_auth">login</a>
      }
    return <>
    <Loading {...loading} />
    <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInputError msg={errors.root?.message} key={2}/>
        <CustomInput type="text" placeholder="Username or email" register={register} label='username' options={
            {required: "Username is required"}
        } error={errors.username} />
        <CustomInput type="password" placeholder={translate('password')} register={register} label='password' options={
            {
                required: "Password is required"
            }
        } error={errors.password}/>
        <div className="text-right text-custom_green-900 mt-2 pr-1">
            <Link to="/reset_request">Forget password?</Link>
        </div>
        <div className="mt-5">
            <CustomButton text={translate("login")} type="submit"/>
            <CustomButton text="Login with Google" transparent={true} fun={login}/> 
            <GitHubLogin
                clientId="07a8752bcbc4ac250cc9"
                redirectUri="http://localhost:3000/login"
                onSuccess={onSuccess}
                onFailure={onFailure}
                className={
                    "relative z-20 overflow-hidden w-full cursor-pointer mt-5 leading-[3.75rem] rounded-xl text-sm sm:text-lg sm:leading-[3.75rem] font-medium transition-all duration-300 ease-in inline-block px-8 whitespace-nowrap " +
                    ("" == "" &&
                      " text-white bg-custom_green-900 border-custom_green-900 disabled:cursor-not-allowed disabled:opacity-75 hover:opacity-75 ")
                  }
                buttonText="Login with Github"/>
            <CustomButton text="Login with Telegram" transparent={true} fun={loginTelegram}/>
            <a href="https://t.me/nisir_elearning_bot/elearning_auth">Login with Telegram</a>
        </div>
    </form></>;
}

export default Login;