import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useDashboardMenuService from './dashboardMenuItems';
import { MenuItem } from '../../types/MenuItems';
import DashboardSubMenu from './useSubMenu';

function DashboardSidebar({
  visibleMenuList,
  setVisibleMenuList,
  setPathName,
}: {
  visibleMenuList: boolean;
  setVisibleMenuList: (visibleMenuList: boolean) => void;
  setPathName: (pathname: string) => void;
}) {
  const [activeLink, setActiveLink] = useState('');
  const [isHover, setIsHover] = useState('');
  const [subMenuList, setSubMenuList] = useState<MenuItem[]>([]);
  const [parentMenuId, setParentMenuId] = useState(0);
  const { menuList } = useDashboardMenuService();
  const handleMouseEnter = (name: string) => {
    setIsHover(name);
  };
  const handleMouseLeave = () => {
    setIsHover('');
  };

  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
    <div className="fixed md:relative bg-custom_green-900 bottom-0 md:top-0 md:left-0 w-full md:w-24 z-20">
      <div className="pr-5 flex md:flex-col justify-around items-center">
        {menuList.map((value) => {
          return (
            <div
              key={value.keyId}
              className="flex items-center justify-between pt-0  mt-7 w-full "
            >
              <span
                className={`left-0  w-1 h-10 ${
                  activeLink == value.id ? 'bg-slate-300' : ''
                }`}
                style={{
                  backgroundColor:
                    isHover == value.id ? 'rgb(203, 213, 225)' : '',
                }}
              ></span>
              <Link
                to={value.link}
                relative="path"
                className={ `ml-5 hover:bg-custom_green-800 border-white/20 bg-custom_green-900 text-white w-14 h-14 border-2 rounded-lg
                  flex items-center justify-center relative text-base transition-all duration-300 ease-in `}
                onClick={() => {
                  setActiveLink(value.id);
                  setParentMenuId(value.keyId);

                  if (value.children) {
                    setSubMenuList(value.children);
                    if (parentMenuId === value.keyId || parentMenuId === 0) {
                      setVisibleMenuList(!visibleMenuList);
                    } else {
                      setVisibleMenuList(true);
                    }
                  } else {
                    setVisibleMenuList(false);
                  }
                }}
              >
                <i>{value.icon}</i>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
    {visibleMenuList && <DashboardSubMenu subMenu={subMenuList} />}
    </>
  );
}

export default DashboardSidebar;
