import useApi from "../../utils/api";
import { Reviewer } from "../../types/Course";

const useReviewerService = () => {
  const { appApi } = useApi();
  const getContent = (id: string) => {
    const response = appApi.get<Reviewer>(`course/review/${id}/`);
    return response;
  };

  const addReviewer = (review: {}) => {
    const response = appApi.post<Reviewer>("course/review/", 
      review,
      {headers: {
        'content-type': 'multipart/form-data'
    }}
      );
    return response;
  };

  const getReviewers = (data: { filterText: string }) => {
    const response = appApi.get<Reviewer[]>("course/review/", {
      params: {
        search: data.filterText,
      },
    });
    return response;
  };
  const editReview = (review:{comment:string,rating:number},id:string) => {
    const response = appApi.patch<Reviewer>(`course/review/${id}/`,review);
    return response;
  };

  const deleteReviewer = (id: string) => {
    const response = appApi.delete<Reviewer>(`course/review/${id}/`);
    return response;
  };

  return {getContent, getReviewers, addReviewer, deleteReviewer,editReview}
};

export default useReviewerService
