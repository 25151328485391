import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../slicers/store";
import { useUserService } from "./UserService";
import useLoading from "../loading/LoadingHook";
import { Languages } from "../../types/Translation";
import { getUser } from "../../slicers/user";

const AuthProvider = (props: { children: any }) => {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const userService = useUserService();
  const loading = useLoading()

  useEffect(() => {
    if (!user.username) {
      loading.startLoading()
      userService.authUser().then(({ data: result }) => {
        console.log(result)
        dispatch(getUser({...result, lang: Languages.ENG}));
        loading.stopLoading()
      }).catch(error => loading.stopLoading());
    }
  }, []);

  // return ( user.username && props.children);
  return props.children
};

export default AuthProvider;
