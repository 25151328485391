import React, { useEffect, useRef } from "react";
import { FieldValues } from "react-hook-form";
import { InputProps } from "../../types/InputTypeProp";
import CustomInputError from "../custom-input-error/CustomInputError";

function CustomTextArea<T extends FieldValues>({rows = 5, label, placeholder, register, options, error}: InputProps<T>) {

    const textArea = register(label, {...options, onChange: (event) => {
      resizeTextArea(event)
    }});

  const resizeTextArea = (e: any) => {
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

    return (<div className="mt-5">
        <textarea {...textArea}  rows={rows} placeholder={placeholder} className="w-full overflow-y-hidden text-base py-2 px-6 text-custom_black-200
transition-all duration-300 ease-in resize-none border-solid focus:outline-custom_green-900 border-custom_green-100 border rounded-xl bg-white" ></textarea>
        <CustomInputError msg={error?.message} />
    </div>);
}

export default CustomTextArea;