import { useState } from "react";
import ChapterList from "../components/chapter/ChapterList";
import ContentList from "../components/content/ContentList";
import CourseSideBar from "../components/course/CourseSideBar";
import CustomCard from "../components/custom-card/CustomCard";
import CustomTabs from "../components/custom-tabs/CustomTabs";
import CustomText from "../components/custom-text/CustomText";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import SideNav from "../components/navbar/SideNav";
import { CustomTab } from "../types/CustomTab";
import useTranslation from "../utils/translation";
import DashboardHeader from "../components/dashboard-header/DashboardHeader";
import DashboardSidebar from "../components/dashboard-sidebar/DashboardSidebar";

function CourseAddDetail() {
  const { translate } = useTranslation();

  const handleRemoveTab = (tab: CustomTab) => {
    if (tabs.findIndex((value) => value.name == tab.name) != -1) {
      const newTabs = tabs.filter((value) => value.name != tab.name);
      setTabs(newTabs);
    }
  };

  const handleAddTab = (tabName: string, id: string) => {
    const newTabs = [
      ...tabs,
      {
        name: tabName,
        id: id,
        element: <ContentList chapter_id={id} />,
        isClosable: true,
      },
    ];
    setTabs(newTabs);
  };

  const courseAddTabs: CustomTab[] = [
    {
      name: "Chapter Lists",
      element: <ChapterList addTab={handleAddTab} />,
      isClosable: false,
      id: "",
    },
  ];
  const [pathname, setPathName] = useState("");
  const [visibleMenuList, setVisibleMenuList] = useState(false);

  const [tabs, setTabs] = useState(courseAddTabs);
  const [hide, setHide] = useState(true);

  return (
    <>
      {/* <Navbar /> */}
      <DashboardHeader />
      <div className="flex">
        <DashboardSidebar
          setVisibleMenuList={setVisibleMenuList}
          visibleMenuList={visibleMenuList}
          setPathName={setPathName}
        />
        <div className="container mx-auto">
          <div className="h-12"></div>
          <CustomCard>
            <h1 className="text-2xl mb-5 font-bold">
              Course <CustomText text={translate("name")} />{" "}
            </h1>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat
              iure placeat earum adipisci excepturi eveniet, ab in ullam quaerat
              dolorem voluptatibus at corporis repudiandae tempora amet delectus
              sed eligendi. Dolore dignissimos ullam iure saepe, optio est
              architecto perspiciatis eius. Sit molestiae tenetur in sapiente!
              Corporis odit delectus explicabo pariatur non!
            </p>
          </CustomCard>
          <div className="flex my-5 flex-wrap">
            <CourseSideBar active="Contents" hide={hide} setHide={setHide} />
            <div className="w-full sm:w-8/12 md:w-10/12">
              <CustomTabs
                tabs={tabs}
                removeTab={handleRemoveTab}
                setHide={setHide}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CourseAddDetail;
