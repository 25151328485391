import { Controller, FieldValues } from "react-hook-form";
import { InputProps } from "../../types/InputTypeProp";
import CustomInputError from "../custom-input-error/CustomInputError";
import Select, { InputActionMeta } from 'react-select'

function CustomDropdown<T extends FieldValues>({label, data, isSearchable, placeholder, control, options, onInputChange, onValueChange, error, className ='', isClearable = true}: InputProps<T>) {

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action != 'input-change' && actionMeta.action != 'menu-close'){
      return;
    }
    onInputChange && onInputChange(newValue)
  }

  return (
    <>
    <Controller
        name={label}
        control={control}
        rules={options}
        render={({ field : {ref, value, onChange}}) => (
        <Select
            ref={ref}
            className={className}
            value={data?.find(c => c.value === value)}
            onChange={val => {
              onValueChange && onValueChange(val?.value); 
              return onChange(val?.value)}}
            isClearable={isClearable}
            isSearchable={isSearchable}
            onInputChange={handleInputChange}
            options={data}
            placeholder={placeholder}
        />
        )}
    />
<CustomInputError msg={error?.message} />
</>
  );
}

export default CustomDropdown;