import DownloadSection from "../components/download/DownloadSection";
import Footer from "../components/footer/Footer";
import User from "../components/auth/User";
import Header from "../components/header/Header";
import useUserPages from "../components/auth/UserPages";

function EmailVerificationCode() {
  const UserPages = useUserPages()
  return (
    <>
      <Header title="Verification code" activeTab="verification_code" />
      <User page={UserPages.email_verification_code} />
      <DownloadSection />
      <Footer />
    </>
  );
}

export default EmailVerificationCode;