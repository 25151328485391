import CustomInput from "../custom-input/CustomInput";
import CustomButton from "../custom-button/CustomButton";
import { useState } from "react";
import { useUserService } from "./UserService";
import { appURL } from "../../utils/api";
import { useDispatch } from "react-redux";
import { BackEndError, UserRegister } from "../../types/UserItem";
import CustomInputError from "../custom-input-error/CustomInputError";
import { useNavigate } from "react-router-dom";
import useLoading from "../loading/LoadingHook";
import Loading from "../loading/Loading";
import useTranslation from "../../utils/translation";
import { getUser } from "../../slicers/user";
import { SubmitHandler, useForm } from "react-hook-form";
import useToast from "../toast/ToastHook";

function Register() {
    const { register, setError, handleSubmit, formState: { errors } } = useForm<UserRegister>();
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const userService = useUserService()
    const loading = useLoading()
    const {translate} = useTranslation()
    const toast = useToast()

    const onSubmit: SubmitHandler<UserRegister>  = (data) => {
        loading.startLoading()
        userService.register(data).then(
            ({data: value}) => {
                if (value.refresh_token) {
                    localStorage.setItem(`${appURL}.authorizationData`, value.access_token);
                    dispatch(getUser(value.user));
                    loading.stopLoading()
                    navigator('/login')
                }
                navigator('/login')
                loading.stopLoading()
                toast.info("Check your email to verify")
            }
        ).catch(error =>
            {
                const errors = error.response.data.error.details;
                for (const err in errors){
                    if (err == 'non_field_errors'){
                        setError('root', {message: errors[err]})
                    }else {
                        setError(err as keyof UserRegister, {message: errors[err]})
                    }
                }
            loading.stopLoading()})
    };


    return <>
    <Loading {...loading} />
    <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput type="text" placeholder={translate("first_name")} register={register} label="first_name" error={errors.first_name} options = {{
            required: "first name is required"
        }} />
        <CustomInput type="text" placeholder={translate("middle_name")} register={register} label="middle_name" error={errors.middle_name} options = {{
            required: "middle name is required"
        }} />
        <CustomInput type="text" placeholder={translate("last_name")} register={register} label="last_name" error={errors.last_name} options = {{
            required: "last name is required"
        }} />
        <CustomInput type="date" placeholder={translate("birth_date")} register={register} label="date_of_birth" error={errors.date_of_birth} options = {{
            required: "date of birth is required"
        }} />
        <CustomInput type="text" placeholder={translate("username")} register={register} label="username" error={errors.username} options = {{
            required: "username is required"
        }} />
        <CustomInput type="email" placeholder={translate('email')} register={register} label="email" error={errors.email} options = {{
            required: "email is required"
        }} />
        <CustomInput type="password" placeholder={translate('password')} register={register} label="password1" error={errors.password1} options = {{
            required: "password is required"
        }} />
        <CustomInput type="password" placeholder="Confirm Password" register={register} label="password2" error={errors.password2} options = {{
            required: "confirm password is required"
        }} />
        <div className="mt-5">
            <CustomButton type="submit" text={translate("create_an_account")}/>
            <CustomButton text="Sign up with Google" form="transparent" />
        </div>
    </form>
    </>;
}

export default Register;