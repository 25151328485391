import { FiSearch, FiBell, FiMenu, FiUser, FiLogOut } from "react-icons/fi";
import logo from "../../assets/images/logo-icon.webp";
import author11 from "../../assets/images/author-11.webp";
import useAuthorization from "../auth/authorization";
import { useSelector } from "react-redux";
import { RootState } from "../../slicers/store";
import { Link } from "react-router-dom";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { useUserService } from "../auth/UserService";


function DashboardHeader(props: any) {

  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user)
  const authorization = useAuthorization()
  const userService = useUserService()

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
const handleLogout = () => {
  userService.logout();
}
  
 return (
 <div className="p-0 flex items-center relative justify-between flex-nowrap bg-custom_black-200 w-full z-50">
    <div className="p-6 bg-custom_black-300">
    <Link to="/">
        <img src={logo} alt="Logo" className="max-w-full align-middle" />
      </Link>
    </div>
    <div className="relative w-[400px]  mr-5 ml-1 lg:w-[500px]">
      <button
        className="absolute w-12 h-12 text-center rounded-lg bg-transparent top-1 right-1
            text-base text-custom_green-900 z-50"
      >
        <i>
          <FiSearch></FiSearch>
        </i>
      </button>
      <div className="relative p-0 block z-40 ">
        <form action="#">
          <input
            type="text"
            placeholder="Search here"
            className="w-full h-14 rounded-full px-7 pr-14 bg-[#28313d] text-white font-normal text-base"
          />
        </form>
      </div>
    </div>
    <div className="flex items-center mr-2 lg:pr-6">
      <div className="relative mx-[3px] lg:mx-3">
        <button className="text-3xl lg:text-4xl text-custom_green-900 relative pt-1">
          <i>
            <FiBell></FiBell>
          </i>
          <span className="w-3 h-3 bg-[#ff6e30] border-2 border-custom_black-200 absolute top-3 right-2 rounded-md"></span>
        </button>
      </div>
    
      <div className="w-12 h-12 flex justify-center items-center ">
      

      { authorization?(
            <Link to={""} onClick={()=>setIsOpen(!isOpen)} className="mx-[3px] lg:mx-3 w-full h-full">
              <img
               src={user.profile_pic} 
               alt="Author"
               className="text-custom_green-900 bg-white w-full h-full rounded-full"
             />
             </Link>
             ):(
             <Link  to={""} onClick={()=>setIsOpen(!isOpen)} className="mx-[3px] lg:mx-3">
             <img
               src={author11}
               alt=""
               className="w-6 h-6 lg:w-14 lg:h-14 rounded-full"
             />
           </Link>)}
     
        { isOpen && (
        <div className="absolute right-4 top-12 mt-2 w-40 bg-white border border-gray-300 rounded-md z-50 shadow-lg">
          <Link to="/profile"
            className=" px-4 py-2 text-gray-800 hover:bg-gray-200 flex justify-start items-center"
          >
            <FiUser className="mr-2"></FiUser>
           <span>Profile</span> 
           
          </Link>
        
          <Link
            to="#"
            onClick={handleLogout}
            className=" px-4 py-2 text-gray-800 hover:bg-gray-200 flex justify-start items-center"
          >
            <FiLogOut className="mr-2"></FiLogOut>
            <span>Signout</span>
           
          </Link>
        </div>
      )}
      </div>
    </div>
</div>)
}



export default DashboardHeader;