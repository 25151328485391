import { MenuItem } from "../../types/MenuItems";
import useMenuListService from "./menuItems";
import { IoMdAdd } from "react-icons/io";
import { FaMinus } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";


function MobileMenu(prop: any) {
    const {menuList} = useMenuListService()
    const { level = 0, menu = menuList } = prop;
    const [ menuState, setMenuState ] = useState<MenuItem[]>(menu);

    function update(idx: number) {
        const newMenuState = [ ...menuState ];
        newMenuState[ idx ].active = !newMenuState[ idx ].active;
        setMenuState(newMenuState);
        console.log(menuState)
    }

    return <div className={level ? '' : "py-7"}>
        <ul className={level ? 'transition-all duration-1000 ease-in' : "border-t border-solid border-custom_green-100"}>
            {menuState.map((value: MenuItem, idx) => {
                return <li className="relative" key={value.id} onClick={e => update(idx)}>
                    <Link to={value.link} className={"block w-full text-left py-3 px-5 border-b border-solid border-custom_green-100 text-sm font-medium text-custom_black-200 "
                        + (level ? level == 1 ? 'pl-10' : 'pl-14' : '')}>
                        {value.name}
                    </Link>
                    {value.children ? <span className="absolute right-5 top-3 cursor-pointer">
                        {value.active ? <i><FaMinus size={15} /></i> : <i><IoMdAdd size={20} /></i>}
                    </span> : ''}
                    {value.active ? <MobileMenu menu={value.children} level={level + 1} /> : ''}
                </li>;
            })}
        </ul>
    </div>;
}


export default MobileMenu;