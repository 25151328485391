import useCommonApi from "../../utils/api";
import { Files } from "./types";

const useFileService = () => {
  const { commonApi } = useCommonApi();

const addFile = (file: FormData) => {
    const response = commonApi.post<Files>("file/",
      file, {headers: {
        'content-type': 'multipart/form-data'
    }});
    return response;
  };

  return {addFile}
}

export default useFileService