import { useEffect, useState } from 'react';

import Loading from '../../loading/Loading';
import useLoading from '../../loading/LoadingHook';
import { FaEllipsisV, FaRedo, FaRegSquare } from 'react-icons/fa';
import CustomCard from '../../custom-card/CustomCard';
import useGroupService from './GroupService';
import { Group } from '../../../types/GroupPermission';
import GroupAdd from './GroupAdd';
import { useOutletContext } from 'react-router-dom';

function GroupList() {
  const [groups, setGroups] = useState<Group[]>([]);

  const visibleMenuList = useOutletContext();
  const useGroup = useGroupService();
  const loading = useLoading();
  useEffect(() => searchGroup(), []);

  const searchGroup = () => {
    loading.startLoading();
    useGroup
      .getGroups({ pageSize: 10, cursor: '', searchText: '' })
      .then(({ data: value }) => {
        setGroups(value as unknown as Group[]);
        loading.stopLoading();
      })
      .catch((error) => loading.stopLoading());
  };
  return (
    <>
      <Loading {...loading} />

      <div className="flex flex-col-reverse md:flex-row mx-10">
        <div className="w-full p-4">
          <div className="flex flex-row">
            <h1 className=" text-custom_green-800 text-2xl font-bold text-center w-full">
              Groups
            </h1>
          </div>
          <div className="flex flex-row gap-4 py-3 text-gray-500">
            <i>
              <FaRegSquare />
            </i>
            <i>
              <FaRedo />
            </i>
            <i>
              <FaEllipsisV />
            </i>
          </div>
          <div className="flex flex-row ">
            <div className="w-full mr-10 mb-10 flex flex-col gap-4">
              {groups?.map((group) => (
                <div key={group.id}>
                  {' '}
                  <CustomCard>
                    <h1 className="text-gray-500 mb-2 font-medium">
                      {group.name}
                    </h1>

                  </CustomCard>{' '}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full p-4">
          <div className="col-span-4">
          <GroupAdd searchGroup={searchGroup} />
          </div>
        </div>
      </div>
    </>
  );
}
export default GroupList;
