import { useEffect, useMemo, useState } from "react";
import { SelectItem } from "../../types/MenuItems";

const range = (start: number, end: number) => {
  let length = end - start + 1;
  /*
    Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};


export interface TableSearchModel {
  search?: string
  view_type?: string
  ps?: number
  pn?: number
}

export const usePagination = <T extends TableSearchModel>(props?: T) => {
  const [total, setTotal] = useState<number>(20);
  const [DOTS, setDOTS] = useState<number>(0);
  const [siblingCount, setSibilingCount] = useState<number>(1);
  const [showSizeChanger, setShowSizeChanger] = useState<boolean>(true);
  const [current, setCurrent] = useState<number>(1);
  const [paginationRange, setPaginationRange] = useState<number[]>([]);
  const [pageSizeOptions, setPageSizeOptions] = useState<SelectItem<number>[]>([
    { value: 1, label: '1' }, { value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }
  ]);
  const [pageSize, setPageSize] = useState(props?.ps || 10);
  const [filterData, setFilterData] = useState<T | TableSearchModel>({
    pn: current,
    ps: pageSize,
    ...props,
  });

  const onPageNumberChange = (pn: number) => {
    setCurrent(pn)
    const data = { ...filterData, pn } as T;
    setFilterData(data);
  };

  const onPageSizeChange = (ps: number) => {
    const data = { ...filterData, ps } as T;
    setFilterData(data);
    setPageSize(ps)
  };

  useEffect(() => {
    const totalPageCount = Math.ceil(total / pageSize);
    
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      console.log("count", range(1, totalPageCount))
      setPaginationRange(range(1, totalPageCount));
    }

    /*
        Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(current - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      current + siblingCount,
      totalPageCount
    );

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 3;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    console.log(shouldShowLeftDots)
    console.log(shouldShowRightDots)

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
        Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);
      console.log("range", [...leftRange, DOTS, totalPageCount])
      setPaginationRange([...leftRange, DOTS, totalPageCount]);
    }

    /*
        Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {

      let rightItemCount = 2 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      console.log("right", [firstPageIndex, DOTS, ...rightRange])
      setPaginationRange([firstPageIndex, DOTS, ...rightRange]);
    }

    /*
        Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      console.log("middle", [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex])
      setPaginationRange([firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]);
    }
  }, [total, pageSize, siblingCount, current, DOTS]);

  return {
    paginationRange, 
    current,
    pageSizeOptions,
    setPageSizeOptions,
    total,
    showSizeChanger,
    onPageNumberChange,
    setTotal,
    setShowSizeChanger,
    setCurrent,
    filterData,
    setFilterData,
    pageSize,
    setPageSize,
    onPageSizeChange,
    setSibilingCount,
    DOTS
  };
};