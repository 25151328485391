import useApi from '../../utils/api';
import { Question } from '../../types/Course';

export const useQuestionService = () => {
    const {appApi} = useApi()

 const addQuestion = (question: Question) => {
    const response = appApi.post<Question>(
        'questions/', question
    );
    return response;
};

const getQuestion = (id: string) => {
  const response = appApi.get<Question>(`questions/${id}/`);
  return response;
};

 const getQuestions = () => {
    const response = appApi.get<Question[]>(
        'question/',
    );
    return response;
};

const deleteQuestion = (id: string) => {
  const response = appApi.delete<Question>(`questions/${id}/`);
  return response;
};

const addValue = (value: string) => {
  const response = appApi.post<Question>(
      'values/', value
  );
  return response;
};

const getValue = (id: string) => {
const response = appApi.get<Question>(`values/${id}/`);
return response;
};

const getValues = () => {
  const response = appApi.get<Question[]>(
      'values/',
  );
  return response;
};

const deleteValue = (id: string) => {
const response = appApi.delete<Question>(`values/${id}/`);
return response;
};
return {addQuestion, getQuestion, getQuestions, deleteQuestion, addValue, getValue, getValues, deleteValue}
}

export default useQuestionService
