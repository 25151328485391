import { FaEllipsisV, FaRedo, FaRegSquare } from "react-icons/fa";
import CustomCard from "../custom-card/CustomCard";
import { BsClockHistory } from "react-icons/bs";
import { BiCheckCircle } from "react-icons/bi";
import ContentAdd from "./ContentAdd";
import { useEffect, useState } from "react";
import useLoading from "../loading/LoadingHook";
import { Content } from "../../types/Course";
import { useContentService } from "./ContentService";

interface ContentListProps {
  chapter_id: string;
}

function ContentList({chapter_id}: ContentListProps) {

  const [question, setQuestion] = useState<JSX.Element>()
  const contentService = useContentService()
  const [contents, setContents] = useState<Content[]>([])
  const loading = useLoading()

  useEffect(()=>searchContent(),[question])
const appendContent = (content: Content):void =>{
  setContents([...contents, content])
}
  const searchContent = () => {
    loading.startLoading()
    contentService.getContents(chapter_id).then(
      ({data: contents}) => {
        setContents(contents)
          loading.stopLoading()
      }
  ).catch(error => loading.stopLoading())
  }      
  return (
    <>
      <div className="w-full pl-4">
        <div className="flex gap-4 py-3 text-gray-500">
          <i>
            <FaRegSquare />
          </i>
          <button  onClick={()=>searchContent()} >
            <i>
              <FaRedo />
            </i>
          </button>
          <i>
            <FaEllipsisV />
          </i>
        </div>
        <div className="flex w-full">
          <div className="w-2/3 flex flex-col gap-4">
            {contents.map(content=>
             <CustomCard>
             <h1 className="text-gray-500 mb-2 font-medium">{content.content_name}</h1>
             <div className="flex justify-between">
               <p>{content.description}</p>
               <div className="flex">
                   <p className="flex items-center mr-4 gap-1"><i className="text-custom_green-800"><BsClockHistory /></i><span className="text-gray-500">1:00 hr</span></p>
                   <p className="flex items-center gap-1"><i className="text-custom_green-800"><BiCheckCircle /></i><span className="text-gray-500">5 Lessons</span></p>
               </div>
             </div>
           </CustomCard>
            )}
              {question}
          </div>
          <div className="w-1/3 pl-4">
           <ContentAdd chapter={chapter_id} appendContent={appendContent} setQuestion={setQuestion} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentList;
