import { useEffect, useState } from "react";
import { BsFillCartPlusFill, BsGlobe } from "react-icons/bs";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { setLanguage } from "../../slicers/user";
import { Languages } from "../../types/Translation";
import useTranslation from "../../utils/translation";
import CustomDropdown from "../custom-input/CustomDropdown";
import Menu from "../menu/Menu";

import SideNav from "./SideNav";
import useAuthorization from '../auth/authorization';
import { RootState } from '../../slicers/store';
import ProfileMenu from './ProfileMenu';
import { UserPermission } from "../../types/UserPermission";
import { useForm } from "react-hook-form";
import { SelectItem } from "../../types/MenuItems";
function Navbar() {
  const [showSideNav, setShowSideNav] = useState(false);
  const [sticky, setSticky] = useState("");
  const { translate } = useTranslation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState<{
    name: string;
    id: Languages;
  }>();
  const languages: SelectItem<string>[] = [
    { label: 'Amharic', value: Languages.AMH },
    { value: Languages.ENG, label: 'English' },
  ];
  const authorization = useAuthorization();
  const user = useSelector((state: RootState) => state.user)

  const applyLanguage = (language: { name: string; id: Languages }) => {
    setSelectedLanguage(language);
    dispatch(setLanguage(language.id));
  };
  const getContentTypeData = (newValue: string) => {

  };
  function cropName(str: string) { return str.substring(0, 1).charAt(0).toUpperCase() }
  function showProfileDropDown(show: boolean) {
    console.log(show);
    return show;
  }
  const { register, control, setError, handleSubmit, formState: { errors } } = useForm<UserPermission>();

  // on render, set listener
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const top = window.innerWidth > 1024 ? 68 : 28;
    const stickyClass =
      scrollTop >= top
        ? ' animate-sticky fixed top-0 left-0 w-full z-30 bg-white shadow-md  '
        : '';
    setSticky(stickyClass);
  }
  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    }
  });
  const children = [
    {
      id: 'courses',
      name: 'Courses',
      link: '/courses',
    },
    {
      id: 'courseDetail',
      name: 'Courses Detail',
      link: '/courses_detail',
    },
    {
      id: 'courseCart',
      name: 'Courses ',
      link: '/courses_cart',
    },
    {
      id: 'categories',
      name: 'Categories',
      link: '/categories',
    },
  ];

  // const isSticky = () => {
  //   /* Method that will fix header after a specific scrollable */
  //   const scrollTop = window.scrollY;
  //   const top = window.innerWidth > 1024 ? 68 : 28;
  //   const stickyClass =
  //     scrollTop >= top
  //       ? " animate-sticky fixed top-0 left-0 w-full z-30 bg-white shadow-md  "
  //       : "";
  //   setSticky(stickyClass);
  // };

  return (
    <>
      <div className={sticky}>
        <div className="container mx-auto">
          <div
            className={
              " flex justify-between items-center px-4 py-4 " +
              (sticky
                ? " border-none roundeded-none "
                : " rounded-2xl mt-7 border border-custom_green-900 ")
            }
          >
            <div>
              <Link
                to="/"
                className="no-underline text-inherit flex place-items-center text-2xl"
              >
                <img src={logo} alt="Logo" className="w-14" />
                <span className="text-custom_dark_green">eLearning</span>
              </Link>
            </div>

            <div className="hidden lg:block">
              <Menu />
            </div>
            <div className="hidden lg:block">
              <ul className="flex items-center">
                <li className="mr-7">
                  <Link
                    to="/courses_cart"
                    replace={true}
                    className="hover:text-custom_green-900 relative text-lg font-medium capitalize text-custom_black-200 block transition-all duration-300 ease-in px-6 py-2.5     leading-tight  rounded   focus:outline-none focus:ring-0   items-center"
                  >
                    <BsFillCartPlusFill size={25} />
                    <span className=" h-6 w-6   absolute top-0 right-1  text-center  bg-custom_green-900 text-white rounded-[50%]">
                      2
                    </span>
                  </Link>
                </li>
                {!authorization.isLoggedIn() && <li className="mr-7"> <Link to="/login" replace={true} className="hover:text-custom_green-900 text-lg font-medium capitalize text-custom_black-200 block transition-all duration-300 ease-in">Sign In</Link></li>}
                {!authorization.isLoggedIn() && <li className="hover:bg-custom_green-900 transition-all duration-300 ease-in hover:text-white text-custom_black-200 last:mr-0 flex items-center h-14 border border-solid border-custom_green-900 rounded-xl bg-white px-9 py-0">
                  <Link
                    to="/register"
                    className="text-lg font-medium capitalize block"
                  >
                    Sign Up
                  </Link>
                </li>}

                <li className="mx-7 p-[0.5em] rounded-md bg-transparent flex place-items-center">
                  <BsGlobe className='text-3xl mr-1' />

                  <CustomDropdown
                    placeholder={translate('languages')}
                    data={languages}
                    register={register}
                    label='content_type'
                    options={{
                      required: 'Language is required'
                    }}
                    isSearchable={true}
                    error={errors.content_type}
                    control={control}
                  />
                </li>
                {authorization.isLoggedIn() && <a href='/profile' onMouseOver={() => setShowProfileMenu(!showProfileMenu)} className='w-[50px] h-[50px] rounded-full bg-custom_green-800 text-white shadow-sm flex justify-center place-items-center cursor-pointer'>{user.profile_pic ? <img src={user.profile_pic} alt="Logo" className="w-29 lg:w-29 rounded-full border-white" /> : <span >{cropName(user.first_name) + cropName(user.last_name)}</span>}</a>}
              </ul>
            </div>
            {showSideNav ? (
              <div
                className="fixed top-0 left-0 w-full h-full bg-custom_black-200 opacity-70 z-20 transition-all duration-300 ease-in"
                onClick={() => setShowSideNav(false)}
              ></div>
            ) : (
              ''
            )}
            <div className="lg:hidden">
              {showSideNav ? <SideNav setShowSideNav={setShowSideNav} /> : ''}
            </div>
            {showProfileMenu ? <ProfileMenu setShowProfileMenu={setShowProfileMenu} /> : ''}

          </div>
        </div>
      </div>
      {showSideNav ? (
        <div
          className="fixed top-0 left-0 w-full h-full bg-custom_black-200 opacity-70 z-20 transition-all duration-300 ease-in"
          onClick={() => setShowSideNav(false)}
        ></div>
      ) : (
        ""
      )}
      <div className="lg:hidden">
        {showSideNav ? <SideNav setShowSideNav={setShowSideNav} /> : ""}
      </div>
    </>
  );
}

export default Navbar;
