import { TCId } from "./modal";
export const arrayToObject = <T>(array: T[], key: keyof T) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    const objKey = item[key] as string
    return {
      ...obj,
      [objKey]: item,
    };
  }, initialValue);
}

export const checkIfAnyExist = <T>(array: T[], key: keyof T, value: any) => {
  return array.some((arr) => arr[key] == value)
}

export const checkIfAllExist = <T>(array: T[], key: keyof T, value: any) => {
  return array.every((arr) => arr[key] == value)
}

export const getById=(list: TCId[], idVal: string)=>{
  return list.find(x => x.id == idVal);
}