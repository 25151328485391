import Footer from "../../components/footer/Footer"
import { FiAlignLeft, FiAlignJustify, FiEyeOff } from "react-icons/fi"
import Dropdown from "../../components/menu/Dropdown"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { Course } from "../../types/Course"
import useCourseService from "../course/CourseService"
import Loading from "../loading/Loading"
import useLoading from "../loading/LoadingHook"
import { Modal } from '../modal/Modal';
import CustomDropdown from "../custom-input/CustomDropdown"
import { SelectItem } from "../../types/MenuItems"
import { useForm } from "react-hook-form"
import CustomText from "../custom-text/CustomText"

function CoursesList(props: any) {
    const [courses, setCourses] = useState<Course[]>([])
    const { register, setError, control, handleSubmit, formState: { errors } } = useForm<Course>();
    // const sortMenuByTime = [{name:"Newest First",value:"Old First"},{name:"Old First"}]
    const getDataYearorMonth: SelectItem<string>[] = [{ label: "This Year", value: "This Year" },
    { label: "This Month", value: "This Month" }, { label: "This Week", value: "This Week" }]
    const courseService = useCourseService()
    const loading = useLoading()

    useEffect(() => {
        loading.startLoading()
        courseService.getCourses({ filterText: '', category_id: null }).then(
            ({ data: result }) => {
                setCourses(result)
                loading.stopLoading()
            }
        ).catch(error => loading.stopLoading())
    }
        , [])
    const [openModel, setOpenModel] = useState("")
    const deleteCategory = async () => {
        let id = openModel === "" ? openModel : ""
        courseService.deleteCourse(id).then(result => {
            courseService.getCourses({ filterText: '', category_id: null }).then(
                ({ data: result }) => {
                    console.log(result)
                    setCourses(result)
                    loading.stopLoading()
                }
            )
            setOpenModel("")
        })
    }
    console.log(props.visibleMenuList)
    return <>
        <Loading {...loading} />
        <div className="pl-0 pb-20 overflow-hidden">
            <div className="flex justify-evenly items-center mr-10 p-5 pl-0 flex-wrap " >
                <div className="flex items-center justify-center mb-10 md:mb-0">
                    <h3 className="text-5xl text-black font-large  font-serif text-center"><CustomText text="Courses" /></h3>
                </div>

                <div className="flex items-baseline flex-wrap gap-4">
                    <div>
                        <CustomDropdown
                            placeholder="Order By"
                            data={[{ label: "Newest First", value: 'newest' }, { label: "Oldest First", value: 'oldest' }]}
                            label='categorys'
                            isSearchable={true}
                            register={register}
                            control={control}
                        />
                    </div>
                    <ul className="flex  items-center justify-evenly flex-wrap mt-10 sm:mt-0 gap-4">
                        <li className="transition duration-300 ease-in hover:ease-out bg-green-700 p-3 rounded-lg border border-gray-300 cursor-pointer " >
                            <button className="text-sm flex items-center justify-center ">
                                <FiAlignJustify size={17} />
                            </button>
                        </li>
                        <li className="transition duration-300 ease-in hover:ease-out  p-3 rounded-lg border border-gray-300 cursor-pointer hover:bg-green-700">
                            <button className="text-sm flex items-center justify-center ">
                                <FiAlignLeft size={17} />
                            </button>

                        </li>
                        <li className="transition duration-300 ease-in hover:ease-out  p-3 rounded-lg border border-gray-300 cursor-pointer hover:bg-green-700">
                            <button className="text-sm flex items-center justify-center ">
                                <FiEyeOff size={17} />
                            </button>
                        </li>
                    </ul>
                    <div className="pt-3 h-1  mt-10 sm:mt-0" >
                        <Link className="text-white px-5 py-3 text-lg rounded-lg border border-gray-300  bg-green-700  hover:bg-black  transition duration-300 ease-out hover:ease-out" to={"/course_add"}>New Course</Link>
                    </div>
                </div>
            </div>
            <div className=" pt-20 pb-20  mr-0 grid lg:grid-cols-2">
                <div className={`fixed top-0 left-0 right-0 z-50
         bg-slate-300 bg-opacity-80 flex flex-wrap items-center justify-center
           w-full p-4 
           overflow-x-hidden 
           overflow-y-auto md:inset-0 h-modal md:h-full  transition ease-in-out duration-1000 ${openModel === "" ? "hidden" : 'block'}`}
                >
                    <Modal setOpenModel={setOpenModel} deleteMethod={deleteCategory} />
                </div>     {courses?.map(course =>
                    <div key={course.id} className="mx-4 px-5 py-5 relative border-1 border-gray-300 shadow-[0_0px_50px_-30px_rgba(0,0,0,0.3)]  rounded-lg dark:shadow-gray-800 
                                flex flex-wrap items-center mt-3 
                                justify-between ">

                        <button type="button"
                            className="text-custom_green-700 bg-transparent absolute top-1 right-1
             hover:bg-custom_green-800 hover:text-gray-900 rounded-lg 
             text-sm p-1.5 ml-auto flex items-center dark:hover:bg-gray-600
              dark:hover:text-white" onClick={() => setOpenModel(course.id)} >
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>

                        <div className="flex ml-3">
                            <div className="mr-5  mb-10 lg:mb-0">
                                <Link to={`/course_add/${course.id}`}>
                                    <img className="rounded w-20 h-20" src={course.course_image as string} alt="" />
                                </Link>
                            </div>

                            <div className="flex flex-col items-center lg:mt-0 ">
                                <div className="flex flex-wrap  self-start mb-5 justify-evenly gap-4">
                                    <a className="px-4 py-0.5 mr-2 bg-orange-400 rounded-3xl text-center text-sm" href="/">Live</a>
                                    <a className=" mr-2 bg-green-100 text-green-500 px-4 py-0.5 rounded-3xl text-center text-sm" href="/">Free</a>
                                    <a className=" mr-2 bg-gray-300 text-blue-500 px-4 py-0.5 rounded-3xl text-center text-sm" href="/">Public</a>
                                </div>
                                <h3 className="text-lg self-start ">
                                    {course?.name}
                                </h3>
                            </div>
                        </div>
                        <div className={`flex flex-wrap item-center  mt-10 justify-evenly gap-4  ${props.visibleMenuList ? 'mt-10' : 'mt-0 lg:mt-10'}`}>
                            <div className="text-xs p-1 w-40 lg:36 bg-gray-300 h-10 rounded-lg  xl:w-44 flex items-center justify-center flex-col">
                                <p className="text-md ">Earned</p>
                                <span className="text-md font-bold text-blue-600">$5,68.00</span>
                            </div>
                            <div className="text-xs p-1 w-40 lg:36 bg-green-100 h-10 rounded-lg  xl:w-44 flex items-center justify-center flex-col">
                                <p className="text-md ">Enrollment’s</p>
                                <span className="text-md font-bold ">1,852</span>
                            </div>
                            <div className="text-xs p-1 w-40 lg:36 bg-red-100 h-10 rounded-lg  xl:w-44 flex items-center justify-center flex-col">
                                <p className="text-md ">Course Rating</p>
                                <span>

                                    <div className="flex items-center">
                                        <p className="ml-2 text-md text-yellow-600 font-medium  dark:text-gray-400">4.5</p>
                                        <svg aria-hidden="true" className="w-5 h-5 sm:w-3 sm:h-3 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                        <svg aria-hidden="true" className="w-5 h-5 sm:w-3 sm:h-3 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Second star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                        <svg aria-hidden="true" className="w-5 h-5 sm:w-3 sm:h-3 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Third star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                        <svg aria-hidden="true" className="w-5 h-5 sm:w-3 sm:h-3 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fourth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                        <svg aria-hidden="true" className="w-5 h-5 sm:w-3 sm:h-3 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
                                    </div>
                                </span>
                            </div>
                            <div className="w-40">
                                <CustomDropdown
                                    placeholder="Time"
                                    data={getDataYearorMonth}
                                    label='categorys'
                                    isSearchable={true}
                                    register={register}
                                    control={control}
                                />
                            </div>



                        </div>

                    </div>
                )}
            </div>
        </div>

    </>
}
export default CoursesList