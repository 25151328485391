import useApi from "../../utils/api";
import { Category } from "../../types/Category";
import { FetchData } from "../../types/FetchData";
import axios from "axios";
import {host} from "../../utils/api"

export const appURL = `${host}/applications/`;

const useCategoryService = () => {
  const { appApi } = useApi();
  const getCategory = (id: string) => {
    const response = appApi.get<Category>(`category/${id}/`);
    return response;
  };

  const addCategory = (category: Category) => {
    const response = appApi.post<Category>("category/", 
      category,
      {headers: {
        'content-type': 'multipart/form-data'
    }}
      );
    return response;
  };

  const getCategories = (data: { pageSize:number,cursor: string, searchText: string}) => {
    const response = appApi.get<FetchData<Category>>("category/", {
      params: {
        ps:data.pageSize,
        cursor:data.cursor,
        search: data.searchText
      },
    });
    return response;
  };

  const deleteCategory = (id: string) => {
    const response = appApi.delete<Category>(`category/${id}/`);
    return response;
  };

  return {getCategories, getCategory, addCategory, deleteCategory}
};

export default useCategoryService
