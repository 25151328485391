import DownloadSection from "../components/download/DownloadSection";
import Footer from "../components/footer/Footer";
import User from "../components/auth/User";
import Header from "../components/header/Header";
import useUserPages from "../components/auth/UserPages";

function Register() {
  const UserPages = useUserPages()
  return (
    <>
      <Header title="Registeration" activeTab="register" />
      <User page={UserPages.register} />
      <DownloadSection />
      <Footer />
    </>
  );
}

export default Register;
