import shape26 from "../../assets/images/shape-26.webp";
import registerLogin from '../../assets/images/register-login.webp';
import { UserPage } from "../../types/UserPages";
import useTranslation from "../../utils/translation";

function User(props: UserPage) {
    const {translate} = useTranslation()
    const { page:{title, element }} = props;
    console.log(props)
    return <div className="py-8  w-full">
        <div className="container mx-auto">
            <div className="p-16 pt-5 border border-solid border-custom_green-100 rounded-xl">
                <div className="flex flex-wrap items-center">
                    <div className="lg:w-1/2 flex-auto max-w-full">
                        <div className="bg-custom_green-100 pt-11 px-10 text-center rounded-xl overflow-hidden relative z-10 mt-12">
                            <div className="w-64 h-64 rounded-[50%] bg-custom_green-900 top-12 left-[5%] absolute -z-10">
                                <img src={shape26} alt="shape 26" className="absolute top-1/2 -translate-y-1/2 left-[25%]" />
                            </div>
                            <div className="w-full">
                                <img src={registerLogin} alt="Register Login" />
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-1/2 flex-auto max-w-full">
                        <div className="max-w-full lg:max-w-[400px] ml-auto mt-11">
                            <h3 className="text-3xl font-medium pb-3">{title} <span
                                className="relative text-custom_green-900 before:absolute before:bg-shape_4 before:bg-center before:bg-cover 
                                            before:bg-no-repeat before:w-32 before:h-3 before:left-1/2 before:-bottom-3 before:-translate-x-1/2">
                                                {title === 'Verification Code'?"":translate('now')}
                            </span>
                            </h3>
                            <div className="pt-7 relative">
                                {element}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default User;