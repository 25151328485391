import { BiPhoneCall } from "react-icons/bi";
import { AiOutlineMail, AiOutlineSkype } from "react-icons/ai";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

function Advert() {
    return (
        <div className="bg-custom_black-200 hidden lg:block">
            <div className="container mx-auto">
                <div className="flex justify-between pb-2">
                    <div className="pt-3">
                        <p className="text-sm text-white font-normal mb-0">
                            All course 28% off for <a className="text-custom_green-900 font-medium inline-block no-underline" href="#"> Librarian people's.</a>
                        </p>
                    </div>
                    <div className="pt-3 relative 
            before:absolute before:w-7 before:h-2 before:bg-shape_1 before:bg-no-repeat before:bg-cover before:bg-center before:top-[50%] before:origin-[50%] before:-left-14
            after:absolute after:w-7 after:h-2 after:bg-shape_2 after:bg-no-repeat after:bg-cover after:bg-center after:top-[50%] after:origin-[50%] after:-right-14">
                        <div className="flex">
                            <p className="text-sm text-white font-normal mb-0 flex items-start">
                                <i className="text-custom_green-900 text-xl pr-4"><BiPhoneCall /></i>
                                <a href="tel:251962150909" className="text-white no-underline">(251) 962-150909</a>
                            </p>
                            <p className="text-sm text-white font-normal mb-0 flex items-start ml-12">
                                <i className="text-custom_green-900 text-xl pr-4"><AiOutlineMail /></i>
                                <a href="mailto:yohannesabera1236@gmail.com" className="text-white no-underline">yohannesabera1236@gmail.com</a>
                            </p>
                        </div>
                    </div>
                    <div className="pt-3">
                        <ul className="flex">
                            <li className="mr-5">
                                <a href="#" className="text-xl text-white no-underline">
                                    <i><FiFacebook /></i>
                                </a>
                            </li>
                            <li className="mr-5">
                                <a href="#" className="text-xl text-white no-underline">
                                    <i><FiTwitter /></i>
                                </a>
                            </li>
                            <li className="mr-5">
                                <a href="#" className="text-xl text-white no-underline">
                                    <i><AiOutlineSkype /></i>
                                </a>
                            </li>
                            <li className="mr-5">
                                <a href="#" className="text-xl text-white no-underline">
                                    <i><FiInstagram /></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Advert;