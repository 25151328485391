import { useForm , SubmitHandler} from "react-hook-form";
import { AiFillFileImage, AiOutlineBold, AiOutlineDelete } from "react-icons/ai"
import { QuestionOption } from "../../types/Course";
import CustomGroupInput from "../custom-input/CustomGroupInput"
import InputText from "../custom-components/InputText";

const QuestionOptions = (props :{value:string[], index:number,setValue:(val:string[])=>void}) => {
  const { register, handleSubmit, formState: { errors } , getValues } = useForm<QuestionOption>();

  const onSubmit: SubmitHandler<QuestionOption> = (data)=>{
    console.log(data)

  }

  return <>
  <form onSubmit={handleSubmit(onSubmit)}>
  <CustomGroupInput register={register} label='value' onValueChange={(val)=>{props.value[props.index] = val.target.value ; props.setValue(props.value)}} placeholder="Value" 
  suffix={<AiOutlineBold></AiOutlineBold>} />
  </form>
  </>
}

export default QuestionOptions

