import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiFillFileImage, AiOutlinePlus } from "react-icons/ai";
import { IoMdSave } from "react-icons/io";
import { Question } from "../../types/Course";
import CustomTextArea from "../custom-input/CustomTextArea";
import QuestionOption from "./QuestionOption";
import useQuestionService from "./QuestionService";
import InputText from "../custom-components/InputText";

interface QuestionFormProps {
  content: string
}

const QuestionForm = ({content}: QuestionFormProps) => {

  const { register, handleSubmit, formState: { errors },reset } = useForm<Question>();
  const questionService = useQuestionService()

  const onSubmit : SubmitHandler<Question> = (data) => {

    data.content = content
    data.options_questions = []
    values.forEach(val => {
      data.options_questions.push({
        value: val,
        question: ""
      });
    });
    questionService.addQuestion(data).then((value)=>{
      if(value){
        // reset()
      }
    })
  };

  const [values, setValues] = useState<string[]>(['']);

  const renderValues = () => {
    return values.map((value, idx) => <QuestionOption value={values} index={idx} setValue={setValues} />)
  }

  const updateValues = (event: string, idx: number) => {
    const newValues = values.map((value, i) => {
      if(i == idx){
        return event
      }
      return value
    })
    setValues(newValues)
  }

  const addValues = () => {
    setValues([...values, ''])
  }

   return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <button type="submit" className="block ml-auto text-custom_green-900"><i><IoMdSave></IoMdSave></i></button>

      <InputText  ></InputText> 
    <CustomTextArea placeholder="Question" register={register} onValueChange={(val)=>{console.log(val,"here")}} label='question'  rows={2} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2">
        {renderValues()}
      <i onClick={addValues} className="text-custom_green-900 text-3xl flex items-center justify-center font-bold cursor-pointer"><AiOutlinePlus></AiOutlinePlus></i>
      </div>
  </form>
  )
}

export default QuestionForm