import Loading from '../loading/Loading';
import useLoading from '../loading/LoadingHook';
import UserPermissionAdd from './UserPermissionAdd';
import { FaEllipsisV, FaRedo, FaRegSquare } from 'react-icons/fa';
import { UserPermission } from '../../types/UserPermission';
import useUserPermissionService from './UserPermissionService';
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import { ColDef, NewValueParams } from 'ag-grid-community';
import Pagination from '../pagination/Pagination';
import { usePagination } from '../pagination/usePagination';
import CustomCard from '../custom-card/CustomCard';
import { User } from '../../types/UserItem';

function UserPermissionList() {
  const userPermissionService = useUserPermissionService();
  const paginator = usePagination()
  const [userPermissions, setUserPermissions] = useState<UserPermission[]>([])

  const gridRef = useRef<any>();
  const [rowData, setRowData] = useState<UserPermission[]>([]);

  const handleValueChange = (event: NewValueParams<UserPermission>) => {
    userPermissionService.updateUserPermission(event.data).then(({ data: value }) => {
    })
  }

  const [columnDefs, setColumnDefs] = useState<ColDef<UserPermission>[]>([
    { field: 'user', headerName: "User" },
    { field: 'content_type', headerName: "Content Type" },
    { field: 'can_view', editable: true, onCellValueChanged: handleValueChange, headerName: "Can View" },
    { field: 'can_create', editable: true, onCellValueChanged: handleValueChange, headerName: "Can Create" },
    { field: 'can_change', editable: true, onCellValueChanged: handleValueChange, headerName: "Can Change" },
    { field: 'can_delete', editable: true, onCellValueChanged: handleValueChange, headerName: "Can Delete" },
  ]);


  const defaultColDef = useMemo(() => ({
    sortable: true,
  }), []);

  const loading = useLoading();
  useEffect(() => searchUserPermission(), [paginator.filterData]);

  const searchUserPermission = () => {
    loading.startLoading();
    userPermissionService
      .getUserPermissions(paginator.filterData)
      .then(({ data: value }) => {
        console.log("value:",value.results)
        let data = value
        setRowData(data as unknown as UserPermission[])
        setUserPermissions(data as unknown as UserPermission[])
        paginator.setTotal(value.count)
        loading.stopLoading();
      })
      .catch((error) => loading.stopLoading());
  };

  return (
    <>
      <Loading {...loading} />
      <div className="w-full md:w-[calc(100%-96px)] justify-center flex flex-col-reverse md:flex-row px-4 py-2">
        <div className="w-full max-w-fit md:w-1/2 lg:w-3/4">
          <div className="flex flex-col">
            <h1 className=" text-custom_green-800 text-2xl font-bold">
              Access Control List
            </h1>
          </div>
          <div className="flex flex-row gap-4 py-3 text-gray-500">
            <i>
              <FaRegSquare />
            </i>
            <i>
              <FaRedo />
            </i>
            <i>
              <FaEllipsisV />
            </i>
          </div>
          <div className=" mx-auto">
            <div className="mb-10 flex h-full flex-col sm:mr-10">
             
              <div className="ag-theme-alpine">

                <AgGridReact
                  ref={gridRef} // Ref for accessing Grid's API

                  rowData={rowData} // Row Data for Rows

                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties

                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  rowSelection='multiple' // Options - allows click selection of rows
                  domLayout='autoHeight'
                  className=''
                  pagination={false}
                />
                <Pagination
                  className="pagination-bar"
                  {...paginator}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
            <UserPermissionAdd searchUserPermission={searchUserPermission} />
        </div>
      </div>
    </>
  );
}
export default UserPermissionList;
