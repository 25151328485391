import { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CustomTab } from "../../types/CustomTab";

function CustomTabs({ tabs, removeTab, setHide }: { tabs: CustomTab[], removeTab: (tab: CustomTab) => void, setHide: (action: boolean) => void }) {
  const [activeTab, setActiveTab] = useState<CustomTab>(tabs[0]);
  const handleRemoveTab = (tab: CustomTab) => {
    if (activeTab.name == tab.name){
      const index = tabs.findIndex(value => value.name == tab.name)
      setActiveTab(tabs[index -1])
    }
    removeTab(tab)
  }
  const handleActiveTab=(tab:CustomTab)=>{
  
    setActiveTab(tab)
   
  }

  return (
    <div>
      <div>
        <div className="pl-4 flex items-baseline">
        <a className="inline-block sm:hidden p-0 mr-2" onClick={() => setHide(false)}>
                                <span className="w-5 h-0.5 bg-custom_black-200 block"></span>
                                <span className="w-5 h-0.5 bg-custom_black-200 block my-1"></span>
                                <span className="w-5 h-0.5 bg-custom_black-200 block"></span>
                            </a>
          <div className="flex border-b">
            {tabs.map((tab) => (
              <div className="relative" key={tab.name}>
                {tab.isClosable && <i onClick={() => handleRemoveTab(tab)} className="absolute right-2 text-gray-400 hover:text-custom_black-200 cursor-pointer"><FaTimes/></i>}
                <h1 key={tab.name} onClick={e => handleActiveTab(tab)} className={`pr-4 mr-2 pb-1 font-bold cursor-pointer ${activeTab.name == tab.name ? 'border-b-2 border-b-custom_green-700' :
               ' text-gray-400 hover:text-custom_black-200 hover:border-b-2 hover:border-b-custom_green-700'}`}>
                {tab.name}
              </h1>
              </div>
            ))}
          </div>
        </div>
        {activeTab.element}
      </div>
    </div>
  );
}

export default CustomTabs;
