import { useEffect, useMemo, useRef, useState } from 'react';

import Loading from '../../loading/Loading';
import useLoading from '../../loading/LoadingHook';
import GroupPermissionAdd from './GroupPermissionAdd';
import { FaEllipsisV, FaRedo, FaRegSquare } from 'react-icons/fa';
import useGroupPermissionService from './GroupPermissionService';
import { GroupPermission } from '../../../types/GroupPermission';
import { AgGridReact } from 'ag-grid-react';
import Pagination from '../../pagination/Pagination';
import { NewValueParams, ColDef, GridReadyEvent } from 'ag-grid-community';
import { usePagination } from '../../pagination/usePagination';

function GroupPermissionList() {
    const paginator = usePagination()

    const userGroupPermissionService = useGroupPermissionService();
    const loading = useLoading();
    useEffect(() => searchGroupPermission(), [paginator.filterData]);


    const gridRef = useRef<any>(); // Optional - for accessing Grid's API
    const [rowData, setRowData] = useState<GroupPermission[]>([]); // Set rowData to Array of Objects, one Object per Row

    const handleValueChange = (event: NewValueParams<GroupPermission>) => {
      userGroupPermissionService.updateGroupPermission(event.data).then(({ data: value }) => {
      })
    }

    // Each Column Definition results in one Column.
    const [columnDefs, setColumnDefs] = useState<ColDef<GroupPermission>[]>([
      { field: 'group', headerName: "Group" },
      { field: 'content_type', headerName: "Content Type" },
      { field: 'can_view', editable: true, onCellValueChanged: handleValueChange, headerName: "Can View" },
      { field: 'can_create', editable: true, onCellValueChanged: handleValueChange, headerName: "Can Create" },
      { field: 'can_change', editable: true, onCellValueChanged: handleValueChange, headerName: "Can Change" },
      { field: 'can_delete', editable: true, onCellValueChanged: handleValueChange, headerName: "Can Delete" },
    ]);

    const defaultColDef = useMemo(() => ({
      sortable: true,
    }), []);

    const gridReadyUpdate = (params: GridReadyEvent<GroupPermission, any>) => {
      gridRef.current.gridApi = params.api;
      gridRef.current.columnApi = params.columnApi;
      gridRef.current.gridApi.sizeColumnsToFit();
      window.onresize = () => {
        gridRef.current.gridApi.sizeColumnsToFit();
      }
    }
  

  const searchGroupPermission = () => {
    loading.startLoading();
    userGroupPermissionService
      .getGroupPermissions(paginator.filterData)
      .then(({ data: value }) => {
        setRowData(value as unknown as GroupPermission[]);
        paginator.setTotal(value.count)
        loading.stopLoading();
      })
      .catch((error) => loading.stopLoading());
    };


  return (
    <>
      <Loading {...loading} />

      <div className="w-full md:w-[calc(100%-96px)] justify-center flex flex-col-reverse md:flex-row px-4 py-2">
        <div className="w-full max-w-fit md:w-1/2 lg:w-3/4">
          <div className="flex flex-col">
            <h1 className=" text-custom_green-800 text-2xl font-bold">
              Access Control List
            </h1>
          </div>
          <div className="flex flex-row gap-4 py-3 text-gray-500">
            <i>
              <FaRegSquare />
            </i>
            <i>
              <FaRedo />
            </i>
            <i>
              <FaEllipsisV />
            </i>
          </div>
          <div className=" mx-auto">
            <div className="mb-10 flex h-full flex-col sm:mr-10">
              {/* {groupPermissions.map((groupPermission) => (
                <div key={groupPermission?.id}>
                  {' '}
                  <CustomCard>
                    <h1 className="text-gray-500 mb-2 font-medium">
                      {groupPermission.content_type}
                    </h1>
                    <div className="flex justify-between">
                      <p>{groupPermission.group}</p>
                    </div>
                  </CustomCard>{' '}
                </div>
              ))} */}
              <div className="ag-theme-alpine">

              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API

                rowData={rowData} // Row Data for Rows

                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties

                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection='multiple' // Options - allows click selection of rows
                domLayout='autoHeight'
                className=''
                onGridReady={gridReadyUpdate}
                pagination={false}
              />
              <Pagination
                className="pagination-bar"
                {...paginator}
              />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4">
            <GroupPermissionAdd searchGroupPermission={searchGroupPermission} />
        </div>
      </div>
    </>
  );
}
export default GroupPermissionList;
