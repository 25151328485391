import CustomInput from '../../custom-input/CustomInput';
import { useState } from 'react';
import CustomButton from '../../custom-button/CustomButton';
import CustomText from '../../custom-text/CustomText';
import useLoading from '../../loading/LoadingHook';
import Loading from '../../loading/Loading';
import useTranslation from '../../../utils/translation';
import { useNavigate } from 'react-router-dom';
import { Group } from '../../../types/GroupPermission';
import useGroupService from './GroupService';
import { SubmitHandler, useForm } from 'react-hook-form';
import useToast from '../../toast/ToastHook';

function GroupAdd(props: { searchGroup: () => void }) {
  const [groupName, setGroupName] = useState<Group>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Group>();

  const useGroup = useGroupService();
  const loading = useLoading();
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const onSubmit: SubmitHandler<Group> = (data) => {
    loading.startLoading();
    console.log(groupName);
    useGroup
      .addGroup(data)
      .then(({ data: value }) => {
        props.searchGroup();
        navigate(``);
        setGroupName(undefined);
        toast.success('Group is added');
        loading.stopLoading();
      })
      .catch((error) => {
        toast.warning(error.message);
        loading.stopLoading();
      });
  };
  return (
    <>
      <Loading {...loading} />
      <h1 className="text-center text-2xl my-2">
        Add <CustomText text="Group" />
      </h1>
      <div className="container mx-auto grid grid-cols-1 mb-10">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <CustomInput
              placeholder={translate('name')}
              register={register}
              label="name"
              options={{ required: 'Group Name is required' }}
              error={errors.name}
            />
            <CustomButton type="submit" text="Add Group" />
          </div>
        </form>
      </div>
    </>
  );
}

export default GroupAdd;
