
import { UserPage } from "../../types/UserPages";
import useTranslation from "../../utils/translation";
import ResetPassword from "./ResetPassword";
import UserEmailVerification from "./UserEmailVerification";
import Login from "./UserLogin";
import Register from "./UserRegister";

const useUserPages = () => {

    const {translate} = useTranslation()

    const UserPages: UserPage = {
        login: {
            title: translate('login'),
            element: <Login />
        },
        register: {
            title: translate("register"),
            element: <Register />
        },
        email_verification_code:{
            title: translate("verification_code"),
            element: <UserEmailVerification />
        },
        reset_request: {
            title: translate("reset_password"),
            element: <ResetPassword isRequest={true} />
        },
        reset_pwd: {
            title: translate("reset_password"),
            element: <ResetPassword isRequest={false} />
        }
    };
    return UserPages;
}

export default useUserPages