import Advert from "../advert/Advert";
import Navbar from "../navbar/Navbar";
import shape8 from "../../assets/images/shape-8.webp";
import shape23 from "../../assets/images/shape-23.webp";
import shape5 from "../../assets/images/shape-5.webp";
import shape6 from "../../assets/images/shape-6.webp";
import shape24 from "../../assets/images/shape-24.webp";
import author11 from "../../assets/images/author-11.webp";
import slider1 from "../../assets/images/slider-1.webp";
import course1 from "../../assets/images/courses-01.webp";
import course2 from "../../assets/images/courses-02.webp";
import course3 from "../../assets/images/courses-03.webp";
import shape11 from "../../assets/images/shape-11.webp";
import shape12 from "../../assets/images/shape-12.webp";
import shape15 from "../../assets/images/shape-15.webp";
import shape13 from "../../assets/images/shape-13.svg";
import shape17 from "../../assets/images/shape-17.webp";
import shape18 from "../../assets/images/shape-18.webp";
import shape19 from "../../assets/images/shape-19.webp";
import shape20 from "../../assets/images/shape-20.webp";
import logo from "../../assets/images/logo.png";
import BreadCrumb from "../bread-crumb/BreadCrumb";
import { FaBookReader, FaStar, FaSearch, FaAngleRight, FaAngleLeft, FaClock, FaBookOpen, FaBook, FaAward, FaQuoteRight, FaQuoteLeft, FaCalendarAlt, FaHeart } from "react-icons/fa"
import DownloadSection from "../download/DownloadSection";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Course } from "../../types/Course";
import { Category } from "../../types/Category";
import useCourseService from "../course/CourseService";
import useLoading from "../loading/LoadingHook";
import useCategoryService from "../category/CategoryService";
import { FetchData } from "../../types/FetchData";
function HeroSection(props: any) {
    const { title, activeTab } = props
    const [courses, setCourses] = useState<Course[]>([]);
    const [categories, setCategories] = useState<FetchData<Category>>();
    const [filterText, setFilterText] = useState("")
    const [cursorCategories, setCursorCategories] = useState("");
    const [category_id, setCategoryId] = useState<any>(null)
    const [cursorCourse, setCursorCourse] = useState("")
    const [cursor, setCursor] = useState("")
    const courseService = useCourseService()
    const categoryService = useCategoryService();
    const loading = useLoading()
    useEffect(() => {
        loading.startLoading()
        courseService.getCourses({filterText: filterText,category_id: category_id}).then(
        ({data: result}) => {
            setCourses(result)
            loading.stopLoading()
        }
    ).catch(error => loading.stopLoading())

        searchCategory()
    }
        , [filterText, cursor, category_id])

    const searchCategory = () => {
        categoryService.getCategories({ pageSize: 5, cursor: cursor, searchText: "" }).then(({ data: res }) => {
            setCategories(res)
        })
    }


    return (
        <>
            <div className="absolute top-0 left-0 w-full z-50">
                <Advert />
                <Navbar />
            </div>
            <div className=" md:h-[750px] lg:h-[895px] pt-[150px] items-center bg-custom_green-100 flex relative overflow-hidden w-full px-4">
                {/* <img src={shape8} alt="shape8" className="absolute top-1/4 md:left-24 animate-custom-spin w-16 md:w-fit" /> */}
                <img src={shape23} alt="shape23" className="absolute bottom-0 left-0" />
                <div className="container w-full flex flex-col md:flex-row lg:flex-row mx-auto justify-center lg:justify-between md:justify-between place-items-center">
                  
                    <div className="">
                        <h4 className="text-[16px] font-medium text-custom_green-900 mb-0 ">Start your favourite course</h4>
                        <div className=" text-[40px] lg:text-[65px] md:text-[55px] font-500 leading-[1.3] mt-[25px] font-semibold">
                            <h2 className="w-[80%] lg:w-[55%]">Now learning from anywhere, and build your
                                <span className="ml-2  relative text-custom_green-900 before:absolute before:bg-shape_4 before:bg-center before:bg-cover 
            before:bg-no-repeat before:w-32 before:h-3 before:left-1/2 before:-bottom-3 before:-translate-x-1/2">
                                    bright career.
                                </span>
                            </h2>
                        </div>
                        <p className="mt-[55px] mb-0 text-[18px] leading-[1.8] text-[#52565b]">It has survived not only five centuries but also the leap into electronic typesetting.</p>

                    </div>
                    <div className="p-5">
                            <img src={slider1} alt="" className="w-full lg:w-[400px] pb-10" />
                        </div>
                </div>

            </div>
            <div className="pt-[80px] w-full">
                <div className="container lg:max-w-[1200px] mx-100% mr-auto ml-auto mx-4">
                    <div className="flex justify-between place-items-center mx-4">
                        <div className="mt-[-37px]">
                            <h2 className="text-[35px] font-medium mb-0 leading-[1.4]">All
                                <span className="mx-2  relative text-custom_green-900 before:absolute before:bg-shape_11 before:bg-center before:bg-cover 
            before:bg-no-repeat before:w-32 before:h-3 before:left-1/2 before:-bottom-3 before:-translate-x-1/2">Courses
                                </span>                                    of GeezTech
                            </h2>
                        </div>
                        <div className="relative max-w-[500px] w-full">
                            <form action="#">
                                <input type="text" name="" placeholder="Search Your course" id="" value={filterText} onChange={(e) => setFilterText(e.target.value)} className="w-full h-[64px] rounded-[10px] border-[1px] border-[solid] border-[rgba(48,146,85,0.2)] py-0 px-[30px] pr-[90px] outline-none transition-[all 0.3s ease-in-out 0s] " />
                                <button className="flex justify-center place-items-center absolute w-[50px] h-[50px] leading-[54px] text-center rounded-[10px] bg-[#deede4] border-0 top-[7px] right-[7px] text-[16px] text-custom_green-900">
                                    <FaSearch />

                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="bg-[#eefbf3] py-[40px] px-6 md:px-[100px] lg:px-[100px] rounded-[10px] mt-[50px] relative box-border mx-4">
                        <div className="touch-pan-y ml-auto mr-auto relative overflow-hidden list-none p-0 z-[1]">
                            <ul className="relative w-full h-full z-[1] transition-transform box-content p-0 m-0 flex flex-wrap pl-0 mb-0 list-none transform-[translate3d(0,0,0)]">
                                {categories?.results.map((value, index) => (
                                    <li className="flex-shrink-0 h-full relative transition-transform" key={value.id}>
                                        <button
                                            className={`${category_id === value.id ? 'border-custom_green-900' : ''
                                                } w-full h-[60px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] bg-white text-[15px] font-medium transition-[all 0.3s ease-in-out 0s] px-[15px] py-0 whitespace-nowrap overflow-hidden text-overflow-ellipsis active:border-custom_green-900 active:text-custom_green-900 hover:border-custom_green-900 hover:text-custom_green-900`}
                                            onClick={() => setCategoryId(value.id)}
                                        >
                                            {value.category_name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <span className="absolute left-0 top-0 pointer-events-none opacity-0 z-[-1000] list-none"></span>
                        </div>

                        <button className="after:content-[''] after:display-none  right-[10px] opacity-[1] w-[40px] h-[40px] leading-[40px] bg-white rounded-[50%] shadow-[0px 0px 20px 0px rgba(48,146,85,0.05)] text-[#212832] cursor-pointer outline-none transition-[all 0.3s ease-in-out 0s] left-auto absolute top-[35%] z-[10] flex place-items-center justify-center" onClick={() => { console.log("next"); setCursor(categories?.next?.cursor || "") }} ><FaAngleRight /></button>
                        <button className="after:content-[''] after:display-none  left-[10px] opacity-[1] w-[40px] h-[40px] leading-[40px] bg-white rounded-[50%] shadow-[0px 0px 20px 0px rgba(48,146,85,0.05)] text-[#212832] cursor-pointer outline-none transition-[all 0.3s ease-in-out 0s] left-auto absolute top-[35%] z-[10] flex place-items-center justify-center" onClick={() => { console.log("pre"); setCursor(categories?.prev?.cursor || "") }}><FaAngleLeft /></button>
                    </div>


                    <div className="box-border mx-4">
                        <div className="block overflow-hidden">
                            <div className="pt-[20px]">
                                <div className="flex  flex-row flex-wrap justify-center md:justify-between lg:justify-between">
                                    {courses?.map(course => <>
                                        <div key={course?.id} className="max-w-full flex-grow-0 flex-shrink-0 basis-auto lg:w-[33%]">
                                            <div className="mt-[30px] border-[1px] border-solid bg-white border-[rgba(48,146,85,0.2)] rounded-[15px] p-[20px] transition-[all 0.3s ease 0s]">
                                                <div className="relative">
                                                    <Link to={`/courses_detail/${course.id}`}>
                                                        <img src={course.course_image as string} alt="" className="w-[350px] h-[300px]  rounded-[15px] align-middle " />
                                                    </Link>
                                                </div>
                                                <div className="pt-[25px]">
                                                    <div className="flex place-items-center justify-between">
                                                        <div className="">
                                                            <Link to={`/courses_detail/${course.id}`}>
                                                                <img src={course.course_image as string} alt="" className="w-[45px] h-[45px]  rounded-[50%]  max-w-full align-middle " />
                                                            </Link>
                                                        </div>
                                                        <div className="flex-1 pl-[12px]">
                                                            <div className="text-[13px] text-[#52565b] 
                                                        font-normal"> Jason Williams</div>
                                                        </div>
                                                        <div className=" w-[80px] text-[13px] h-[35px] 
                                                    leading-[35px] bg-[#e7f8ee] text-custom_green-900 
                                                    inline-block text-center rounded-[5px] py-0 px-[10px]">Science</div>
                                                    </div>
                                                </div>
                                                <h4 className="text-[calc(1.275rem + 0.3vw)]">
                                                    <Link to={`/courses_detail/${course.id}`} className="font-medium text-[#212832]  hover:text-custom_green-900 text-[16px]  mt-[13px] inline-block leading-[1.4]">{course.name}</Link>

                                                </h4>
                                                <div className="flex justify-between  pt-[10px]">
                                                    <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaClock /></i> 08 hr 15 mins</span>
                                                    <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaBookOpen /></i> 29 Leacturers</span>
                                                </div>

                                                <div className="p-[10px] bg-[#eefbf3] rounded-[10px] flex justify-between place-items-center mt-[20px]">
                                                    <div className="whitespace-nowrap flex place-items-center">
                                                        <span className="text-[16px] font-700 text-custom_green-900 mr-[5px]">{course.course_price}</span>
                                                        <span className="text-[13px] font-700 text-[#212832] line-through mr-[5px]">400br </span>
                                                    </div>
                                                    <div className="whitespace-nowrap flex place-items-center">
                                                        <span className="text-[13px] font-700 text-[#212832] mr-[5px]">4.9</span>
                                                        <span className="text-[13px] font-700 text-[#ffba00] line-through mr-[5px] flex "><FaStar /><FaStar /><FaStar /><FaStar /><FaStar className="text-[#d0d0d0]" /></span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>)}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[40px] text-center">
                        <Link to={"/courses_detail"} className="w-[300px] h-[300px] border-[1px] border-solid rounded-[10px]  border-custom_green-900 text-custom_green-900 hover:bg-custom_green-900  hover:text-white font-medium leading-[58px] bg-[#e7f8ee] size-[1.125rem] py-[1rem] px-[2.188rem]">Other Course</Link>
                    </div>
                    <div className="pt-[80px] w-full">
                        <div className="w-full">
                            <div className="bg-[#e7f8ee] rounded-[10px] px-4 lg:px-[100px] md:px-[100px] pt-[20px] pb-[50px] mb-[40px] relative">
                                <img src={shape12} alt="" className="absolute bottom-[30px] left-[30px] max-w-full animate-custom-spin" />
                                <img src={shape12} alt="" className="absolute top-[30px] right-[30px] max-w-full animate-custom-spin" />
                                <img src={shape13} alt="" className="absolute top-[50%] right-[34%] w-[130px]" />
                                <div className="flex flex-col lg:flex-row md:flex-row place-items-center flex-wrap">
                                    <div className=" w-full lg:w-1/2 md:w-1/2 flex-shrink-0 flex-grow-0 basis-auto max-w-full ">
                                        <div className="max-w-[415px] mt-[25px] pb-[20px] ">
                                            <h5 className="text-custom_green-900 font-medium mb-[20px] text-[20px]">Become A Instructor</h5>
                                            <h2 className="text-[35px] font-medium mb-0 leading-[1.4]">You can join with Edule as an <span className="text-custom_green-900 relative">instructor?</span> </h2>

                                        </div>
                                    </div>
                                    <div className="w-1/2 flex-shrink-0 flex-grow-0 basis-auto max-w-full text-right mt-[30px] ">
                                        <div className=" ">
                                            <Link to={"/courses_detail"} className="w-[200px] md:w-auto lg:w-auto font-medium text-white hover:bg-[#212832] text-[16px]  mt-[13px] inline-block leading-[3.75rem] py-0 px-[2.188rem] lg:text-[1.125rem] rounded-[10px] bg-custom_green-900 border-custom_green-900">Drop Information</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="pt-[40px] pb-[80px] ">
                        <div className="w-full mx-auto ">
                            <div className="pb-[11px] text-center">
                                <h5 className="text-[20px] font-medium text-custom_green-900 mb-[20px] ">Over 1,235+ Course</h5>
                                <h2 className="text-[35px] font-medium text-[#212832] leading-[1.4] mb-0 ">How It
                                    <span className="ml-2  relative text-custom_green-900 before:absolute before:bg-shape_4 before:bg-center before:bg-cover 
            before:bg-no-repeat before:w-32 before:h-3 before:left-1/2 before:-bottom-3 before:-translate-x-1/2">
                                        Work?
                                    </span>
                                </h2>

                            </div>

                            <div className="flex flex-wrap justify-center lg:justify-between md:justify-between pt-[20px] place-items-center mx-4">
                                <div className="w-[300px] rounded-[10px] bg-[#e7f8ee] p-[38px] pb-[35px] mt-[30px] z-[1] relative overflow-hidden">
                                    <img src={shape15} alt="" className="absolute left-[-17px] top-0 z-[-1] max-w-full" />
                                    <div> <i className="flex justify-center place-items-center w-[65px] h-[65px] leading-[68px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] bg-white text-custom_green-900 text-[32px] transition-[all 0.3s ease 0s]"><FaSearch /></i>
                                    </div>
                                    <div className="pt-[35px]">
                                        <h3 className="text-2xl font-medium text-[#212832] mb-0">Find Your Course</h3>
                                        <p className="text-[14px] text-[#696969] mb-0 mt-[15px] leading-[1.8]">It has survived not only counturie also leap into electronic</p>
                                    </div>
                                </div>
                                <div className="mt-[30px]">
                                    <img src={shape17} alt="" className="max-w-full rotate-90 md:rotate-0 lg:rotate-0" />
                                </div>
                                <div className="w-[300px] rounded-[10px] bg-[#e7f8ee] p-[38px] pb-[35px] mt-[30px] z-[1] relative overflow-hidden">
                                    <img src={shape15} alt="" className="absolute right-[-34px] top-[-7px] z-[-1] max-w-full" />
                                    <div> <i className="flex justify-center place-items-center w-[65px] h-[65px] leading-[68px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] bg-white text-custom_green-900  text-[32px] transition-[all 0.3s ease 0s]"><FaBook /></i>
                                    </div>
                                    <div className="pt-[35px]">
                                        <h3 className="text-2xl font-medium text-[#212832] mb-0">Book A Seat</h3>
                                        <p className="text-[14px] text-[#696969] mb-0 mt-[15px] leading-[1.8]">It has survived not only counturie also leap into electronic</p>
                                    </div>
                                </div>
                                <div className="mt-[30px]">
                                    <img src={shape17} alt="" className="max-w-full rotate-90 md:rotate-0 lg:rotate-0" />
                                </div>
                                <div className="w-[300px] rounded-[10px] bg-[#e7f8ee] p-[38px] pb-[35px] mt-[30px] z-[1] relative overflow-hidden">
                                    <img src={shape15} alt="" className="absolute right-[-10px] bottom-[-25px] z-[-1] max-w-full" />
                                    <div> <i className="flex justify-center place-items-center w-[65px] h-[65px] leading-[68px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] bg-white text-custom_green-900  text-[32px] transition-[all 0.3s ease 0s]"><FaAward /></i>
                                    </div>
                                    <div className="pt-[35px]">
                                        <h3 className="text-2xl font-medium text-[#212832] mb-0">Get Certificate</h3>
                                        <p className="text-[14px] text-[#696969] mb-0 mt-[15px] leading-[1.8]">It has survived not only counturie also leap into electronic</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <DownloadSection />

            </div>
            <div className="pt-[80px] w-full mt-[-0.3125rem]">
                <div className="w-full lg:max-w-[1200px] mx-auto">
                    <div className="pb-[11px] text-center">
                        <h5 className="font-medium text-[20px] text-custom_green-900 mb-[20px]">Student Testimonial</h5>
                        <h2 className="font-medium text-[35px] leading-[1.4] mb-0">Feedback Form <span className="ml-2  relative text-custom_green-900 before:absolute before:bg-shape_4 before:bg-center before:bg-cover 
            before:bg-no-repeat before:w-32 before:h-3 lg:before:left-1/2 before:right-[15px] before:-bottom-3 before:-translate-x-1/2">
                            Student
                        </span></h2>

                    </div>
                    <div className="pt-[50px]">
                        <div className="touch-pan-y mx-auto relative overflow-hidden list-none p-0 z-[1]">
                            <div className="transform-[translate3d(0px,0px,0px)] duration-0ms relative w-full h-full z-[1] flex box-content transition-transform flex-col lg:flex-row justify-center lg:justify-between md:justify-between place-items-center flex-wrap gap-3">
                                <div className=" w-[300px] lg:w-[570px] md:w-[570px] text-center border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] p-[45px]  pb-[40px] relative overflow-hidden flex-shrink-0 h-full transition-transform">
                                    <div className="">
                                        <div className="relative ">
                                            <img src={course1} alt="" className="block w-[90px] h-[90px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] p-[8px] my-0 mx-auto max-w-full rounded-[50%]" />
                                            <i className=" w-[30px] h-[30px] leading-[30px] text-center bg-custom_green-900 text-white text-[13px] rounded-[50%]  absolute bottom-[-13px] left-0 right-0 mx-auto my-0 flex justify-center place-items-center"> <FaQuoteLeft /></i>

                                        </div>
                                        <span className="mt-[35px] text-[13px] font-700 text-[#ffba00] line-through mr-[5px] flex justify-center"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar className="text-[#d0d0d0]" /></span>

                                    </div>
                                    <div className="text-center pt-[15px]">
                                        <p className="font-normal text-[15px] text-[#52565b] mb-0">Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a galley of type and scrambled it to make type specimen book has survived not five centuries but also the leap into electronic.</p>
                                        <h4 className="font-medium text-[22px] mb-0 mt-[16px]">Sara Alexandra </h4>
                                        <span className="text-custom_green-900 text-[14px] mt-[5px] block">Product Designer, USA</span>
                                    </div>
                                </div>
                                <div className=" w-[300px] lg:w-[570px] md:w-[570px] text-center border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] p-[45px]  pb-[40px] relative overflow-hidden flex-shrink-0 h-full transition-transform">
                                    <div className="">
                                        <div className="relative ">
                                            <img src={course1} alt="" className="block w-[90px] h-[90px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] p-[8px] my-0 mx-auto max-w-full rounded-[50%]" />
                                            <i className=" w-[30px] h-[30px] leading-[30px] text-center bg-custom_green-900 text-white text-[13px] rounded-[50%]  absolute bottom-[-13px] left-0 right-0 mx-auto my-0 flex justify-center place-items-center"> <FaQuoteLeft /></i>

                                        </div>
                                        <span className="mt-[35px] text-[13px] font-700 text-[#ffba00] line-through mr-[5px] flex justify-center"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar className="text-[#d0d0d0]" /></span>

                                    </div>
                                    <div className="text-center pt-[15px]">
                                        <p className="font-normal text-[15px] text-[#52565b] mb-0">Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a galley of type and scrambled it to make type specimen book has survived not five centuries but also the leap into electronic.</p>
                                        <h4 className="font-medium text-[22px] mb-0 mt-[16px]">Sara Alexandra </h4>
                                        <span className="text-custom_green-900 text-[14px] mt-[5px] block">Product Designer, USA</span>
                                    </div>
                                </div>
                                <div className=" w-[300px] lg:w-[570px] md:w-[570px] text-center border-[1px] border-solid border-[rgba(48,146,85,0.2)] rounded-[10px] p-[45px]  pb-[40px] relative overflow-hidden flex-shrink-0 h-full transition-transform">
                                    <div className="">
                                        <div className="relative ">
                                            <img src={course1} alt="" className="block w-[90px] h-[90px] border-[1px] border-solid border-[rgba(48,146,85,0.2)] p-[8px] my-0 mx-auto max-w-full rounded-[50%]" />
                                            <i className=" w-[30px] h-[30px] leading-[30px] text-center bg-custom_green-900 text-white text-[13px] rounded-[50%]  absolute bottom-[-13px] left-0 right-0 mx-auto my-0 flex justify-center place-items-center"> <FaQuoteLeft /></i>

                                        </div>
                                        <span className="mt-[35px] text-[13px] font-700 text-[#ffba00] line-through mr-[5px] flex justify-center"><FaStar /><FaStar /><FaStar /><FaStar /><FaStar className="text-[#d0d0d0]" /></span>

                                    </div>
                                    <div className="text-center pt-[15px]">
                                        <p className="font-normal text-[15px] text-[#52565b] mb-0">Lorem Ipsum has been the industry's standard dummy text since the 1500s, when an unknown printer took a galley of type and scrambled it to make type specimen book has survived not five centuries but also the leap into electronic.</p>
                                        <h4 className="font-medium text-[22px] mb-0 mt-[16px]">Sara Alexandra </h4>
                                        <span className="text-custom_green-900 text-[14px] mt-[5px] block">Product Designer, USA</span>
                                    </div>
                                </div>
                            </div>
                            <div className="relative bottom-0 mt-[43px] h-[18px] text-center transition-[0.3s opacity] transform-[taranslate-3d(0,0,0)] z-[10]">
                                <span tabIndex={0} role="button" aria-label="Go to slide 1" className="active:bg-custom_green-900 w-[13px] h-[13px] rounded-[50%] opacity-[1] my-0 mx-[7px] transition-[all 0.3s ease 0s] cursor-pointer">.</span>
                            </div>
                            <div className="relative bottom-0 mt-[43px] h-[18px] text-center transition-[0.3s opacity] transform-[taranslate-3d(0,0,0)] z-[10]">
                                <span tabIndex={0} role="button" aria-label="Go to slide 1" className="active:bg-custom_green-900 w-[13px] h-[13px] rounded-[50%] opacity-[1] my-0 mx-[7px] transition-[all 0.3s ease 0s] cursor-pointer">.</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="pt-[80px] w-full mt-[-0.3125rem]">
                <div className="w-full lg:max-w-[1200px] mx-auto">


                    <div className="mx-auto bg-[#e7f8ee] py-[60px] px-[100px] rounded-[10px] relative">

                        <img src={shape19} alt="" className="max-w-full absolute top-[60px] left-5 lg:right-[31%]" />

                        <img src={shape20} alt="" className="max-w-full absolute top-[50px] right-[45px] animate-custom-spin " />
                        <div className="pb-[11px] ">
                            <h2 className="font-medium text-2xl lg:text-[35px] mb-0 leading-[1.4]">Be supportor of<span className="ml-2  relative text-custom_green-900 before:absolute before:bg-shape_4 before:bg-center before:bg-cover 
            before:bg-no-repeat before:w-32 before:h-3 before:left-1/2 before:-bottom-3 before:-translate-x-1/2">
                                GeezTech.
                            </span></h2>

                        </div>
                        <div className="flex flex-wrap justify-between place-items-center pt-[15px]">
                            <div className="touch-pan-y mx-auto relative overflow-hidden list-none p-0 z-[1]">
                                <div className="translate-y-0   duration-[0ms] relative w-full h-full z-[1] flex transform box-content ">
                                    <div className="w-[126px] mr-[85px] mt-[30px] text-center flex-shrink-0 h-full relative transform">
                                        <img src={logo} alt="" className="max-w-full" />

                                    </div>
                                    <div className="w-[126px] mr-[85px] mt-[30px] text-center flex-shrink-0 h-full relative transform">
                                        <img src={logo} alt="" className="max-w-full" />

                                    </div>
                                    <div className="w-[126px] mr-[85px] mt-[30px] text-center flex-shrink-0 h-full relative transform">
                                        <img src={logo} alt="" className="max-w-full" />

                                    </div>
                                    <div className="w-[126px] mr-[85px] mt-[30px] text-center flex-shrink-0 h-full relative transform">
                                        <img src={logo} alt="" className="max-w-full" />

                                    </div>
                                    <div className="w-[126px] mr-[85px] mt-[30px] text-center flex-shrink-0 h-full relative transform">
                                        <img src={logo} alt="" className="max-w-full" />

                                    </div>
                                    <div className="w-[126px] mr-[85px] mt-[30px] text-center flex-shrink-0 h-full relative transform">
                                        <img src={logo} alt="" className="max-w-full" />

                                    </div>
                                    <div className="w-[126px] mr-[85px] mt-[30px] text-center flex-shrink-0 h-full relative transform">
                                        <img src={logo} alt="" className="max-w-full" />

                                    </div>
                                </div>
                                <span className="absolute left-0 top-0 pointer-events-none opacity-0 z-[-1000] "></span>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
            <div className="py-[80px] w-full mt-[-0.3125rem]">
                <div className="w-full lg:max-w-[1200px] mx-auto">
                    <div className="pb-[11px] text-center">
                        <h5 className="font-medium text-[20px] text-custom_green-900 mb-[20px]">Latest News</h5>
                        <h2 className="font-medium text-[35px] leading-[1.4] mb-0">Educational Tips &  <span className="ml-2  relative text-custom_green-900 before:absolute before:bg-shape_4 before:bg-center before:bg-cover 
            before:bg-no-repeat before:w-32 before:h-3 lg:before:left-1/2 before:right-[15px] before:-bottom-3 before:-translate-x-1/2">
                            Tricks
                        </span></h2>

                    </div>
                    <div className="pt-[50px]">
                        <div className="flex  flex-row flex-wrap justify-center md:justify-between lg:justify-between mx-4">
                            <div className="max-w-full flex-grow-0 flex-shrink-0 basis-auto lg:w-[33%]">
                                <div className="mt-[30px] border-[1px] border-solid bg-white border-[rgba(48,146,85,0.2)] rounded-[15px] p-[20px] transition-[all 0.3s ease 0s]">
                                    <div className="relative">
                                        <Link to={"/courses_detail"}>
                                            <img src={course1} alt="" className="w-full rounded-[15px] max-w-full align-middle " />
                                        </Link>
                                    </div>
                                    <div className="pt-[25px]">
                                        <div className="flex place-items-center justify-between">
                                            <div className="">
                                                <a href="#">
                                                    <img src={course1} alt="" className="w-[45px] h-[45px]  rounded-[50%]  max-w-full align-middle " />
                                                </a>
                                            </div>
                                            <div className="flex-1 pl-[12px]">
                                                <div className="text-[13px] text-[#52565b] font-normal"> Jason Williams</div>
                                            </div>
                                            <div className=" w-[80px] text-[13px] h-[35px] leading-[35px] bg-[#e7f8ee] text-custom_green-900 inline-block text-center rounded-[5px] py-0 px-[10px]">Science</div>
                                        </div>
                                    </div>
                                    <h4 className="text-[calc(1.275rem + 0.3vw)]">
                                        <a href="#" className="font-medium text-[#212832]  hover:text-custom_green-900 text-[16px]  mt-[13px] inline-block leading-[1.4]">Data Science and Machine Learning with Python - Hands On!</a>

                                    </h4>
                                    <div className="flex justify-between  pt-[10px]">
                                        <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaCalendarAlt /></i> 21 March, 2021</span>
                                        <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaHeart /></i> 2,568+</span>
                                    </div>
                                    <div className="flex justify-between place-items-center">
                                        <a href="#" className="h-[45px] border-[1px] py-0 px-[20px] leading-[39px] border-solid rounded-[10px]  border-[rgba(48,146,85,0.2)] mt-[30px] text-[15px] text-custom_green-900 hover:bg-custom_green-900  hover:text-white font-medium leading-[58px] bg-[#e7f8ee]">Read More</a>

                                    </div>
                                </div>

                            </div>
                            <div className="max-w-full flex-grow-0 flex-shrink-0 basis-auto lg:w-[33%]">
                                <div className="mt-[30px] border-[1px] border-solid bg-white border-[rgba(48,146,85,0.2)] rounded-[15px] p-[20px] transition-[all 0.3s ease 0s]">
                                    <div className="relative">
                                        <a href="#">
                                            <img src={course1} alt="" className="w-full rounded-[15px] max-w-full align-middle " />
                                        </a>
                                    </div>
                                    <div className="pt-[25px]">
                                        <div className="flex place-items-center justify-between">
                                            <div className="">
                                                <a href="#">
                                                    <img src={course1} alt="" className="w-[45px] h-[45px]  rounded-[50%]  max-w-full align-middle " />
                                                </a>
                                            </div>
                                            <div className="flex-1 pl-[12px]">
                                                <div className="text-[13px] text-[#52565b] font-normal"> Jason Williams</div>
                                            </div>
                                            <div className=" w-[80px] text-[13px] h-[35px] leading-[35px] bg-[#e7f8ee] text-custom_green-900 inline-block text-center rounded-[5px] py-0 px-[10px]">Science</div>
                                        </div>
                                    </div>
                                    <h4 className="text-[calc(1.275rem + 0.3vw)]">
                                        <a href="#" className="font-medium text-[#212832]  hover:text-custom_green-900 text-[16px]  mt-[13px] inline-block leading-[1.4]">Data Science and Machine Learning with Python - Hands On!</a>

                                    </h4>
                                    <div className="flex justify-between  pt-[10px]">
                                        <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaCalendarAlt /></i> 21 March, 2021</span>
                                        <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaHeart /></i> 2,568+</span>
                                    </div>

                                    <div className="flex justify-between place-items-center">
                                        <a href="#" className="h-[45px] border-[1px] py-0 px-[20px] leading-[39px] border-solid rounded-[10px]  border-[rgba(48,146,85,0.2)] mt-[30px] text-[15px] text-custom_green-900 hover:bg-custom_green-900  hover:text-white font-medium leading-[58px] bg-[#e7f8ee]">Read More</a>

                                    </div>
                                </div>

                            </div>
                            <div className="max-w-full flex-grow-0 flex-shrink-0 basis-auto lg:w-[33%]">
                                <div className="mt-[30px] border-[1px] border-solid bg-white border-[rgba(48,146,85,0.2)] rounded-[15px] p-[20px] transition-[all 0.3s ease 0s]">
                                    <div className="relative">
                                        <a href="#">
                                            <img src={course1} alt="" className="w-full rounded-[15px] max-w-full align-middle " />
                                        </a>
                                    </div>
                                    <div className="pt-[25px]">
                                        <div className="flex place-items-center justify-between">
                                            <div className="">
                                                <a href="#">
                                                    <img src={course1} alt="" className="w-[45px] h-[45px]  rounded-[50%]  max-w-full align-middle " />
                                                </a>
                                            </div>
                                            <div className="flex-1 pl-[12px]">
                                                <div className="text-[13px] text-[#52565b] font-normal"> Jason Williams</div>
                                            </div>
                                            <div className=" w-[80px] text-[13px] h-[35px] leading-[35px] bg-[#e7f8ee] text-custom_green-900 inline-block text-center rounded-[5px] py-0 px-[10px]">Science</div>
                                        </div>
                                    </div>
                                    <h4 className="text-[calc(1.275rem + 0.3vw)]">
                                        <a href="#" className="font-medium text-[#212832]  hover:text-custom_green-900 text-[16px]  mt-[13px] inline-block leading-[1.4]">Data Science and Machine Learning with Python - Hands On!</a>

                                    </h4>
                                    <div className="flex justify-between  pt-[10px]">
                                        <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaCalendarAlt /></i> 21 March, 2021</span>
                                        <span className="text-[14px] font-normal text-[#212832] mr-[20px] flex place-items-center leading-[1]"><i className="text-custom_green-900 mr-5px text-[18px] mr-[5px] leading-[1]"><FaHeart /></i> 2,568+</span>
                                    </div>

                                    <div className="flex justify-between place-items-center">
                                        <a href="#" className="h-[45px] border-[1px] py-0 px-[20px] leading-[39px] border-solid rounded-[10px]  border-[rgba(48,146,85,0.2)] mt-[30px] text-[15px] text-custom_green-900 hover:bg-custom_green-900  hover:text-white font-medium leading-[58px] bg-[#e7f8ee] transition-all transition-[0.4s all linear]">Read More</a>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}

export default HeroSection;
