import useApi from "../../../utils/api";
import { FetchData } from "../../../types/FetchData";
import { User } from "../../../types/UserItem"

const useUserService = () => {
  const { commonApi } = useApi();

  const getUser = (id: string) => {
    const response = commonApi.get<User>(`users/${id}/`);
    return response;
  };
 
  const getUsers = (data: { pageSize:number,cursor: string, searchText: string }) => {
    const response = commonApi.get<User[]>("users/", {
      params: {
        ps:data.pageSize,
        cursor:data.cursor,
        search: data.searchText
      },
    });
    return response;
  };
 

  return {getUsers, getUser }
};

export default useUserService
