import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import './App.css';

import Login from './pages/Login';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Register from './pages/Register';
import AuthProvider from './components/auth/AuthProvider';
import ResetPassword from './pages/ResetPassword';
import About from './pages/About';
import FAQ from './pages/FAQ';
import CourseDetail from './pages/CourseDetail';
import Homepage from './pages/Homepage';
import ErrorNotFoundPage from './pages/404Page';
import InstructorDashboard from './pages/InstructorDashboard';
import CoursesList from './components/Instructer/CourseList';
import Overview from './components/Instructer/Overview';
import CourseAddPage from './pages/CourseAddPage';
import Video from './pages/Video';
import CourseAddDetail from './pages/CourseAddDetail';

import Toasts from './components/toast/ToastList';

import CategoryContainer from './pages/Category';
import UserPermissionList from './components/acl/UserPermissionList';
import Profile from "./pages/Profile";
import UsersList from './components/acl/user/UserList';
import GroupPermissionList from './components/acl/group-permission/GroupPermissionList';
import GroupList from './components/acl/group-permission/GroupList';
import EmailVerificationCode from './pages/EmailVerificationCode';

const router = createBrowserRouter(
  [
  {
    path: '/',
    element: <Homepage />
  },
  {
    path: '/course_add',
    element: (
      <AuthProvider>
        <CourseAddPage />
      </AuthProvider>
    ),
  },
  {
    path: '/course_add/:id',
    element: <CourseAddDetail />,
  },
  
  {
    path: '/reset_request',
    element: <ResetPassword isRequest={true} />,
  },
  {
    path: '/password/reset/:uuid/:token',
    element: <ResetPassword isRequest={false} />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/verify_email/:code',
    element: <EmailVerificationCode />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/faq',
    element: <FAQ />,
  },
  {
    path: '/courses_detail/:id',
    element: (
      <AuthProvider>
        <CourseDetail />
      </AuthProvider>
    ),
  },
  {
    path: '/instructor_dashboard',
    element: (
      <AuthProvider>
        <InstructorDashboard />
      </AuthProvider>
    ),
    children: [

      {
        path:'', element:(
          <Navigate replace to='category' />
        )
      },
      {
        path: 'allcourse',
        element: (
          <AuthProvider>
            <CoursesList />
         </AuthProvider>
        ),
      },
      {
        path: 'overview',
        element: (
          <AuthProvider>
            <Overview />
          </AuthProvider>
        ),
      },

      {
        path: 'user_permission_list',
        element: (
          <AuthProvider>
            <UserPermissionList  />
          </AuthProvider>
        ),
      },
      {
        path: 'user_list',
        element: (
          <AuthProvider>
            <UsersList />
          </AuthProvider>
        ),
      },
      {
        path: 'group_permission_list',
        element: (
          <AuthProvider>
            <GroupPermissionList />
          </AuthProvider>
        ),
      },
      {
        path: 'group_list',
        element: (
          <AuthProvider>
            <GroupList />
          </AuthProvider>
        ),
      },
      {
        path: 'category',
        element: (
          <AuthProvider>
            <CategoryContainer />
          </AuthProvider>
        ),
      },
    ],
  },

  {
    path: 'courses',
    element:<Homepage />

  },

  {
    path: '/courses_detail/:id/video',
    element: <Video />,
  },
  {
    path: 'profile',
    element: <AuthProvider>
      <Profile></Profile></AuthProvider>
  },
  {
    path: '*',
    element: <ErrorNotFoundPage />,
  },
]
);

function App() {
  return (
    <>
      <Toasts />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
