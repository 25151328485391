import CustomInput from "../custom-input/CustomInput";
import CustomDropdown from "../custom-input/CustomDropdown";
import { useEffect, useState } from "react";
import CustomButton from "../custom-button/CustomButton";
import CustomCard from "../custom-card/CustomCard";
import QuestionAdd from "../question/QuestionAdd";
import { Chapter, Content } from "../../types/Course";
import useTranslation from "../../utils/translation";
import CustomImageFileInput from "../custom-input/CustomImageFileInput";
import CustomFileInput from "../custom-input/CustomFileInput";
import { useContentService } from "./ContentService";
import { SubmitHandler, useForm } from "react-hook-form";
import useLoading from "../loading/LoadingHook";
import useToast from '../toast/ToastHook';
import { SelectItem } from "../../types/MenuItems";
import useFileService from "../file/FileService";
import { ContentType, FileTypes } from "../../types/Enums";

interface ContentAddProps {
  setQuestion: (question: JSX.Element) => void,
  appendContent: (content : Content)=> void,
  chapter: string;
}

interface ContentType {
  id: number,
  name: string,
  content: JSX.Element
}

function ContentAdd({setQuestion, chapter,appendContent}: ContentAddProps) {
  const [fileComponent, setFileComponent] = useState<JSX.Element>()
  const { register, control, handleSubmit, formState: { errors },reset,getValues } = useForm<Content>();
  const {translate} = useTranslation();
  const [id, setId] = useState<string>();
  const contentService = useContentService()
  const loading = useLoading()
  const toast= useToast();
  const fileService = useFileService();

  const contentTypes: SelectItem<number>[] = [
    { value: 100, label: "Video Content" },
    { value: 101, label: "Image Content" },
    { value: 102, label: "Document Content" },
    { value: 103, label: "Question" },
    { value: 104, label: "Youtube video" },

  ];
  let questionComponent = <></>

  useEffect(() => {
    questionComponent = id ? <QuestionAdd content={id} /> :<></>
  }, [id])

  // const isYouTubeUrl = (url: string): boolean => {
  //   const youtubePattern = /^(https?:\/\/)?(www\.)?youtube\.com\/(watch\?v=|embed\/|v\/)([a-zA-Z0-9_-]{11})/;    
  //   return youtubePattern.test(urlComponent);
  // }

  const urlComponent = (<CustomInput
    placeholder={translate('youtubeurl')}
    register={register}
    options={{
      required: 'url is required'
    }}
    label="url"
    error={errors.url}
  />)

  const fileComp = (content_type: keyof typeof FileTypes) =>  <CustomFileInput register={register}
    fileType={content_type}
    label="file_field"
    options={{
      required:  "file is required" 
    }}
    placeholder="File "
    error={errors?.file_field} />
  

  const handleContentType = (content_type?: keyof typeof FileTypes) => {
    if (content_type && [ContentType.IMAGE, ContentType.DOCUMENT, ContentType.VIDEO].some(value=> value === content_type) ){
      setFileComponent(fileComp(content_type));  
    } else if(content_type && content_type === ContentType.YOUTUBE_VIDEO){
      setFileComponent(urlComponent)
    } else {
      setFileComponent(<></>)
    }
  }

  const onSubmit : SubmitHandler<Content> = (data) => {
    const formData = new FormData();
    data.file_field?.length && formData.append(
      "url",
      data?.file_field[0],
      (data.file_field[0] as unknown as File)?.name
    );
    formData.append("file_type",'101');
    data.chapter = chapter;

    if([ContentType.IMAGE, ContentType.DOCUMENT, ContentType.VIDEO].some(value=> value === data.content_type)){
      fileService.addFile(formData).then(({data:value})=>{
        data.url = value.url
        contentService.addContent(data).then(result=>{
          setId(result.data.id)
          appendContent(result.data)
          toast.success("succesfully added")
          reset()
          console.log(getValues("content_type"))
          loading.stopLoading()
       }).catch((error) =>  {console.error(error); loading.stopLoading()}); 
      });
    } else{
      contentService.addContent(data).then(result=>{
        toast.success("succesfully added")
        setId(result.data.id)
        appendContent(result.data)
        setQuestion(result.data.id ? <QuestionAdd content={result.data.id} /> :<></>)
        reset()

        loading.stopLoading()
     }).catch((error) =>  {console.error(error); loading.stopLoading()});   
    };
  }
  

  return (
    <>
      <CustomCard>
        <form onSubmit={handleSubmit(onSubmit)}>
           <CustomInput
            placeholder={translate('title')}
            register={register}
            label="title"
            options={{
              required: "Title is required"
            }}
            error={errors.title}
          />
           <CustomInput
            placeholder={translate('description')}
            register={register}
            label="description"
            options={{
              required: "Description is required"
            }}
            error={errors.description}
          />
           <CustomDropdown
            placeholder={translate("content_type")}
            data={contentTypes}
            key="name"
              register={register}
              label='content_type'
              options={{
                required: 'Content Type is required'
              }}
              isSearchable={false}
              control={control}
              error={errors.content_type}
              onValueChange={handleContentType}
          />  
          {fileComponent}
          <CustomButton type="submit" text="Add Content" />
          
        </form>
      </CustomCard>
    </>
  );
}

export default ContentAdd;
