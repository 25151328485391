import Footer from '../../../components/footer/Footer';
import { useEffect, useState } from 'react';
import useUserService from './UserService';
import Loading from '../../loading/Loading';
import useLoading from '../../loading/LoadingHook';
import { User } from '../../../types/UserItem';
import useUserApi from './UserApi';
import { FaEllipsisV, FaRedo, FaRegSquare } from 'react-icons/fa';
import CustomCard from '../../custom-card/CustomCard';
import { useOutletContext } from 'react-router-dom';
function UsersList() {
  const [users, setUsers] = useState<User[]>([]);

  const visibleMenuList = useOutletContext();
  const userService = useUserService();
  const loading = useLoading();

  useEffect(() => {
    loading.startLoading();

    userService
      .getUsers({ pageSize: 10, cursor: '', searchText: '' })
      .then(({ data: users }) => {
        setUsers(users);
        loading.stopLoading();
      })
      .catch((error) => loading.stopLoading());
  }, []);

  return (
    <>
      <Loading {...loading} />

        <div >
          <div className="flex flex-row">
            <h1 className=" text-custom_green-800 text-2xl font-bold">Users</h1>
          </div>
          <div className="flex flex-row gap-4 py-3 text-gray-500">
            <i>
              <FaRegSquare />
            </i>
            <i>
              <FaRedo />
            </i>
            <i>
              <FaEllipsisV />
            </i>
          </div>
          <div className="flex flex-row ">
            <div className="w-full mr-10 mb-10 flex flex-col gap-4">
              {users.map((user) => (
                <div>
                  {' '}
                  <CustomCard>
                    <h1 className="text-gray-500 mb-2 font-medium">
                      {user.username}
                    </h1>
                    <div className="flex justify-between">
                      <p>{user.username}</p>
                    </div>
                  </CustomCard>{' '}
                </div>
              ))}
            </div>
          </div>
        </div>
    </>
  );
}
export default UsersList;
