function CustomCard(props: any) {
    const { light = false } = props;

    return (
        <div className={"border border-solid border-custom_green-100 rounded-xl px-8 py-4 transition-all duration-300 ease-in group " + (
            light ? "hover:border-custom_green-100 hover:bg-custom_green-100" :
                "hover:border-custom_green-900"
        )}>
            {props.children}
        </div>
    );
}

export default CustomCard;