import DownloadSection from "../components/download/DownloadSection";
import Footer from "../components/footer/Footer";
import User from "../components/auth/User";
import Header from "../components/header/Header";
import useUserPages from "../components/auth/UserPages";

function ResetPassword(props: { isRequest: boolean; }) {
    const UserPages = useUserPages()
    
    return (
        <>
            <Header title="Reset" activeTab="reset_pwd" />
            <User page={props.isRequest ? UserPages.reset_request : UserPages.reset_pwd} />
            <DownloadSection />
            <Footer />
        </>
    );
}

export default ResetPassword;
