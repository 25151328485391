

import { useEffect, useState } from 'react'
import { FiClock, FiSearch } from 'react-icons/fi'
import { Link, useLocation, useOutletContext } from 'react-router-dom'

import Advert from '../components/advert/Advert'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import Navbar from '../components/navbar/Navbar'
import CategoryAdd from '../components/category/CategoryAdd'
import CategoryList from '../components/category/CategoryList'
import useCategoryService from '../components/category/CategoryService'
import { Category } from '../types/Category'

import useCategoryApi from '../components/category/CategoryApi'
import CatagoryList from '../components/category/CategoryList'
import Loading from '../components/loading/Loading'
import useLoading from '../components/loading/LoadingHook'

function CategoryContainer() {
 const [categoriesList,setCategoriesList] = useState<Category[]>([]);
 const [cursor,setCursorData]=useState({next:null,pre:null})
   const categoryApi = useCategoryApi()
   const loading = useLoading()
   const [load, setLoad] = useState(false)

      
  useEffect(()=>{
   categoryApi.getCat({pageSize:10,cursor:"1", searchText:"", setCategoriesList})
  },[load])
  
    return (
      <>
    <Loading {...loading} />
    <div className="flex md:flex-row flex-col justify-evenly container mx-auto gap-4">
          <div className='w-full md:w-2/5'>
              <CategoryList categories = {categoriesList} 
              setCategoriesList={setCategoriesList}
              cursor={cursor} setCursorData={setCursorData}/>
          </div>
          <div className='w-full md:w-2/5'>
          <CategoryAdd setLoad={setLoad} setCategoriesList={setCategoriesList}/>
          </div>
     </div>
      </>
    )

}


export default CategoryContainer

