import {
    BsFileEarmarkPlusFill,
    BsFillBookmarksFill,
    BsFillPieChartFill,
  } from "react-icons/bs";
  import { FaUserAlt } from "react-icons/fa";

export const sidebars = [
    { icon: <BsFillBookmarksFill></BsFillBookmarksFill>, name: "Contents" },
    { icon: <FaUserAlt></FaUserAlt>, name: "Users and Progress" },
    { icon: <BsFileEarmarkPlusFill></BsFileEarmarkPlusFill>, name: "Files" },
    { icon: <BsFillPieChartFill></BsFillPieChartFill>, name: "Reports" },
  ];