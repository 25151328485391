import React from 'react';
import CustomInputError from '../custom-input-error/CustomInputError';
import { FieldValues } from 'react-hook-form';
import { InputProps } from '../../types/InputTypeProp';

function CustomCheckbox<T extends FieldValues>({ type = 'checkbox', label, data, val, id, placeholder, register, options, error}: InputProps<T>) {

  return (
    <>
    <div className="flex flex-row space-x-5">
      <input
        type={type} {...register(label, options)} placeholder={placeholder} 
        className="hover:bg-custom_green-800 border-custom_green-700 "
      />
      <label htmlFor="Can view">{placeholder}</label>
    </div>
    <CustomInputError msg={error?.message} />
    </>
  );
}

export default CustomCheckbox;
