import React, { useState } from 'react';
import { Modal } from '../modal/Modal';
import useCategoryApi from './CategoryApi';
import useCategoryService from './CategoryService';
//import useCategoryApi from './CategoryApi';

const CatagoryList = (prop: any) => {
  let pageSizeList = [2, 5, 10, 20, 25];
  const { categories, setCategoriesList, cursor, setCursorData } = prop;
  const [openModel, setOpenModel] = useState('');
  const categoryService = useCategoryService();
  const categoryApi = useCategoryApi();
  const [pageSize, setPageSize] = useState(pageSizeList[0]);
  const deleteCategory = async () => {
    let id = openModel == '' ? openModel : '';
    categoryApi.deleteCat({
      id: openModel ? openModel : '',
      setOpenModel,
      setCategoriesList,
    });
    categoryApi.getCat({
      pageSize: pageSize,
      cursor: '',
      searchText: '',
      setCategoriesList,
    });
  };
  const nextHandler = async () => {
    categoryApi.getCat({
      pageSize: pageSize,
      cursor: '1',
      searchText: '',
      setCategoriesList,
    });
  };
  const prevHandler = async () => {
    categoryApi.getCat({
      pageSize: pageSize,
      cursor: '2',
      searchText: '',
      setCategoriesList,
    });
  };
  const pageSizeHandeler = (event: any) => {
    setPageSize(event.target.value);
    categoryApi.getCat({
      pageSize: event.target.value,
      cursor: '2',
      searchText: '',
      setCategoriesList,
    });
  };
  return (
    <>

      <h1 className=" text-custom_green-800 text-2xl p-4 font-bold">
              Category List
      </h1>    
      <div className="flex flex-wrap overflow-y-auto border-2 border-custom_green-700 h-72  p-4 ">
     
        <div
          className={`fixed top-0 left-0 right-0 z-50
         bg-slate-300 bg-opacity-80 flex items-center justify-center
           w-full p-4 
           overflow-x-hidden 
           overflow-y-auto md:inset-0 h-modal md:h-full  transition ease-in-out duration-1000 ${
             openModel === '' ? 'hidden' : 'block'
           }`}
        >
          <Modal setOpenModel={setOpenModel} deleteMethod={deleteCategory} />
        </div>
        {categories?.map((category: any) => (
          <div
            key={category.id}
            className="border-2 border-custom_green-700 mt-4 mr-4 h-12  pl-5 pr-2 py-3 rounded-xl 
         text-custom_green-800 flex items-center justify-between"
          >
            <p className="mr-5"> {category.category_name} </p>
            <button
              type="button"
              className="text-custom_green-700 bg-transparent
             hover:bg-custom_green-800 hover:text-gray-900 rounded-lg 
             text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600
              dark:hover:text-white"
              onClick={() => setOpenModel(category.id)}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
        ))}
      </div>

      <nav aria-label="Page navigation example" className='pt-4 flex justify-center'>
        <ul className="inline-flex -space-x-px">
          <li>
            <button
              className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border
       border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800
        dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={() => prevHandler()}
            >
              Previous
            </button>
          </li>
          <li>
            <div
              className="px-3 py-2 mx-10 leading-tight text-gray-500 bg-white border
       border-gray-300  hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800
        dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <select
                className="border-none outline-none bg-transparent"
                onChange={pageSizeHandeler}
              >
                {pageSizeList.map((page) => (
                  <option value={page}>{page}</option>
                ))}
              </select>
            </div>
          </li>
          <li>
            <button
              className="px-3 py-2 leading-tight
       text-gray-500 bg-white border border-gray-300 rounded-r-lg
        hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700
         dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={() => nextHandler()}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default CatagoryList;
