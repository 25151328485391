import { FaEllipsisV, FaRedo, FaRegSquare } from "react-icons/fa";
import CustomCard from "../custom-card/CustomCard";
import { BsClockHistory } from "react-icons/bs";
import { BiCheckCircle } from "react-icons/bi";
import ChapterAdd from "./ChapterAdd";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useChapterService from "./ChapterService";
import { Chapter } from "../../types/Course";
import useLoading from "../loading/LoadingHook";
import Loading from "../loading/Loading";
import { CustomTab } from "../../types/CustomTab";

function ChapterList({  addTab }: {  addTab: (tab: string,id:string) => void }) {
  const id = useParams().id!
  const chapterService = useChapterService()
  const [chapters, setChapters] = useState<Chapter[]>([])
  const loading = useLoading()

  useEffect( () => searchChapters()
        , [])

  const searchChapters = () => {
    loading.startLoading()
    chapterService.getChapters({filterText: '', courseId: id}).then(
      ({data: chapters}) => {
          setChapters(chapters)
          loading.stopLoading()
      }
  ).catch(error => loading.stopLoading())
  }


  return (
    <>
    <Loading {...loading} />
      <div className="w-full pl-4">
        <div className="flex gap-4 py-3 text-gray-500">
          <i>
            <FaRegSquare />
          </i>
          <i>
            <FaRedo />
          </i>
          <i>
            <FaEllipsisV />
          </i>
        </div>
        <div className="flex w-full flex-wrap">
          <div className="w-full md:w-2/3 flex flex-col gap-4">
        
            {chapters.map(chapter => <div  onClick={() => chapter.id && addTab(chapter.chapter_title,chapter.id)}> <CustomCard>
              <h1 className="text-gray-500 mb-2 font-medium">{chapter.chapter_name}</h1>
              <div className="flex justify-between">
                <p>{chapter.chapter_title}</p>
                <div className="flex">
                    <p className="flex items-center mr-4 gap-1"><i className="text-custom_green-800"><BsClockHistory /></i><span className="text-gray-500">1:00 hr</span></p>
                    <p className="flex items-center gap-1"><i className="text-custom_green-800"><BiCheckCircle /></i><span className="text-gray-500">5 Lessons</span></p>
                </div>
              </div>
            </CustomCard> </div>)}
          </div>
          <div className="w-full md:w-1/3 pl-4">
           <ChapterAdd course={id} reload={searchChapters}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChapterList;
